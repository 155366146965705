import { dateHelper, Dayjs, dayjs } from 'core-helpers';
import { DATE_FORMAT_6 } from 'src/constants/date';
import { getFirstDayOfMonth, getLastDayOfMonth } from './calendar';
import { ViewEnum } from 'src/pages/Schedule';

export const timestampToDate = (timestamp: number) => {
  return new Date(timestamp * 1000);
};

export const compareDates = (a: Date, b: Date) => {
  const dayA = dayjs(a);
  const dayB = dayjs(b);
  if (dayA.isBefore(dayB)) {
    return -1;
  }

  if (dayA.isAfter(dayB)) {
    return 1;
  }

  return 0;
};

export const compareDatesDayjs = (a: Dayjs, b: Dayjs) => {
  const startOfA = a.startOf('day');
  const startOfB = b.startOf('day');

  return startOfA.diff(startOfB, 'day') >= 1;
};

export const compareCompletedDate = (a: Dayjs, b: Dayjs) => {
  const startOfA = a.startOf('day');
  const startOfB = b.startOf('day');

  return startOfA <= startOfB;
};

export const formatDateYMD = (date?: Date): string | null => {
  return dateHelper.formatDate({
    date,
    format: DATE_FORMAT_6
  });
};

export const getCalendarRange = (currentDate: Dayjs) => {
  const firstDayOfMonth = getFirstDayOfMonth(currentDate);
  const lastDayOfMonth = getLastDayOfMonth(currentDate);

  const dayOfWeekOfFirstDayOfMonth = dayjs(firstDayOfMonth).day();
  const dayOfWeekOfLastDayOfMonth = dayjs(lastDayOfMonth).day();

  let daysToPreviousSunday = dayOfWeekOfFirstDayOfMonth;
  let daysToNextSaturday = 6 - dayOfWeekOfLastDayOfMonth;

  const startDate = dayjs(firstDayOfMonth).subtract(
    daysToPreviousSunday,
    ViewEnum.DAY
  );
  const endDate = dayjs(lastDayOfMonth).add(daysToNextSaturday, ViewEnum.DAY);

  return {
    startDate: dayjs(startDate).format(DATE_FORMAT_6),
    endDate: dayjs(endDate).format(DATE_FORMAT_6)
  };
};

export const getRangeOfWeek = (currentDate: Dayjs) => {
  const dayOfWeekOfCurrentDate = currentDate.day();

  let daysToPreviousSunday = dayOfWeekOfCurrentDate;
  let daysToNextSaturday = 6 - dayOfWeekOfCurrentDate;

  const startDate = currentDate.subtract(daysToPreviousSunday, ViewEnum.DAY);
  const endDate = currentDate.add(daysToNextSaturday, ViewEnum.DAY);

  return {
    startDate: dayjs(startDate).format(DATE_FORMAT_6),
    endDate: dayjs(endDate).format(DATE_FORMAT_6)
  };
};

export const getListDateBetweenARangeOfDates = (currentDate: Dayjs) => {
  const start = getCalendarRange(currentDate).startDate;
  const end = getCalendarRange(currentDate).endDate;

  const arr = [];
  for (
    const date = new Date(start);
    date <= new Date(end);
    date.setDate(date.getDate() + 1)
  ) {
    arr.push(new Date(date));
  }
  return {
    date: arr.map((v) => v.toISOString().slice(8, 10)),
    dateMonYear: arr.map((v) => v.toISOString().slice(0, 10))
  };
};

export const Svg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
    >
      <path
        d="M8 8C6.9 8 5.95833 7.60833 5.175 6.825C4.39167 6.04167 4 5.1 4 4C4 2.9 4.39167 1.95833 5.175 1.175C5.95833 0.391667 6.9 0 8 0C9.1 0 10.0417 0.391667 10.825 1.175C11.6083 1.95833 12 2.9 12 4C12 5.1 11.6083 6.04167 10.825 6.825C10.0417 7.60833 9.1 8 8 8ZM0 16V13.2C0 12.65 0.141667 12.1333 0.425 11.65C0.708333 11.1667 1.1 10.8 1.6 10.55C2.45 10.1167 3.40833 9.75 4.475 9.45C5.54167 9.15 6.71667 9 8 9H8.35C8.45 9 8.55 9.01667 8.65 9.05C8.51667 9.35 8.40417 9.6625 8.3125 9.9875C8.22083 10.3125 8.15 10.65 8.1 11H8C6.81667 11 5.75417 11.15 4.8125 11.45C3.87083 11.75 3.1 12.05 2.5 12.35C2.35 12.4333 2.22917 12.55 2.1375 12.7C2.04583 12.85 2 13.0167 2 13.2V14H8.3C8.4 14.35 8.53333 14.6958 8.7 15.0375C8.86667 15.3792 9.05 15.7 9.25 16H0ZM14 17L13.7 15.5C13.5 15.4167 13.3125 15.3292 13.1375 15.2375C12.9625 15.1458 12.7833 15.0333 12.6 14.9L11.15 15.35L10.15 13.65L11.3 12.65C11.2667 12.4167 11.25 12.2 11.25 12C11.25 11.8 11.2667 11.5833 11.3 11.35L10.15 10.35L11.15 8.65L12.6 9.1C12.7833 8.96667 12.9625 8.85417 13.1375 8.7625C13.3125 8.67083 13.5 8.58333 13.7 8.5L14 7H16L16.3 8.5C16.5 8.58333 16.6875 8.675 16.8625 8.775C17.0375 8.875 17.2167 9 17.4 9.15L18.85 8.65L19.85 10.4L18.7 11.4C18.7333 11.6 18.75 11.8083 18.75 12.025C18.75 12.2417 18.7333 12.45 18.7 12.65L19.85 13.65L18.85 15.35L17.4 14.9C17.2167 15.0333 17.0375 15.1458 16.8625 15.2375C16.6875 15.3292 16.5 15.4167 16.3 15.5L16 17H14ZM15 14C15.55 14 16.0208 13.8042 16.4125 13.4125C16.8042 13.0208 17 12.55 17 12C17 11.45 16.8042 10.9792 16.4125 10.5875C16.0208 10.1958 15.55 10 15 10C14.45 10 13.9792 10.1958 13.5875 10.5875C13.1958 10.9792 13 11.45 13 12C13 12.55 13.1958 13.0208 13.5875 13.4125C13.9792 13.8042 14.45 14 15 14ZM8 6C8.55 6 9.02083 5.80417 9.4125 5.4125C9.80417 5.02083 10 4.55 10 4C10 3.45 9.80417 2.97917 9.4125 2.5875C9.02083 2.19583 8.55 2 8 2C7.45 2 6.97917 2.19583 6.5875 2.5875C6.19583 2.97917 6 3.45 6 4C6 4.55 6.19583 5.02083 6.5875 5.4125C6.97917 5.80417 7.45 6 8 6Z"
        fill="currentColor"
      />
    </svg>
  );
};

import { Svg } from 'src/assets/icons/DatePickerIcon/svg';
import SearchForm, { FieldType, FormField } from 'src/components/SearchForm';
import {
  full_address,
  branch,
  branch_director,
  building_type,
  construct_at,
  code,
  name,
  note,
  sale_man,
  searchHolder,
  number_of_floors,
  structure,
  total_apartments
} from 'src/constants/property/propertyLabel';
import { buildingOptions, structureOptions } from 'src/enums/property';
import { IParamSearch } from 'src/models/property';
import { formatDateYMD } from 'src/utils/date';

const refineSearchFields = [
  {
    fieldType: FieldType.INPUT,
    name: 'code',
    label: code,
    placeholder: `${code}${searchHolder}`
  },
  {
    fieldType: FieldType.INPUT,
    name: 'name',
    label: name,
    placeholder: `${name}${searchHolder}`
  },
  {
    fieldType: FieldType.SELECT,
    name: 'building_type',
    label: building_type,
    placeholder: `${building_type}${searchHolder}`,
    options: buildingOptions
  },
  {
    fieldType: FieldType.INPUT,
    name: 'full_address',
    label: full_address,
    placeholder: `${full_address}${searchHolder}`
  },
  {
    fieldType: FieldType.INPUT,
    name: 'number_of_floors',
    label: number_of_floors,
    placeholder: `${number_of_floors}${searchHolder}`,
    inputType: 'number',
    maxLength: 3
  },
  {
    fieldType: FieldType.INPUT,
    name: 'total_apartments',
    label: total_apartments,
    placeholder: `${total_apartments}${searchHolder}`
  },
  {
    fieldType: FieldType.DATE_PICKER,
    name: 'construct_at',
    label: construct_at,
    placeholder: `${construct_at}${searchHolder}`,
    suffixIcon: (
      <span className="text-[#555555]">
        <Svg />
      </span>
    )
  },
  {
    fieldType: FieldType.SELECT,
    name: 'structure',
    label: structure,
    placeholder: `${structure}${searchHolder}`,
    options: structureOptions
  },
  {
    fieldType: FieldType.INPUT,
    name: 'branch',
    label: branch,
    placeholder: `${branch}${searchHolder}`
  },
  {
    fieldType: FieldType.INPUT,
    name: 'branch_director',
    label: branch_director,
    placeholder: `${branch_director}${searchHolder}`
  },
  {
    fieldType: FieldType.INPUT,
    name: 'sale_man',
    label: sale_man,
    placeholder: `${sale_man}${searchHolder}`
  },
  {
    fieldType: FieldType.INPUT,
    name: 'note',
    label: note,
    placeholder: `${note}${searchHolder}`
  }
] as FormField[];

interface IParam {
  setParam: (evt: any) => void;
}

const RefineSearch = (props: IParam) => {
  const handleSearch = (value: IParamSearch) => {
    props.setParam({
      ...value,
      construct_at: formatDateYMD(value?.construct_at)
    });
  };

  return (
    <div className="bg-grey-200 p-[20px] w-full text-[var(--text-default-color)]">
      <SearchForm formFields={refineSearchFields} onSearch={handleSearch} />
    </div>
  );
};

export default RefineSearch;

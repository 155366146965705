import { styled } from 'core-ui';

export const StyledSchedule = styled.div`
  .ant-form-item {
    margin: 0 !important;
    height: 24px !important;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
  }
  .ant-select .ant-select-selector,
  .ant-input-affix-wrapper,
  .ant-input {
    border-radius: 3px !important;
  }
`;

export const StyledFormItem = styled.div`
  .ant-select .ant-select-selector,
  .ant-input-affix-wrapper,
  .ant-input {
    border-radius: 3px !important;
    font-size: 16px;
  }
`;

export const StyledCalendar = styled.div`
  .ant-table-wrapper .ant-table-thead > tr > th {
    padding: 12px;
  }
  .ant-form-item {
    margin: 0 !important;
    height: 24px !important;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
  }
`;

export const StyledEditSchedule = styled.div`
  .ant-form-item-control-input-content > input {
    height: 43px;
    font-size: 16px;
    color: var(--text-default-color);
  }
  .ant-select.ant-select-in-form-item,
  .ant-select-selector,
  .ant-select-selection-search-input,
  .ant-picker .ant-picker-input > input {
    height: 43px !important;
    font-size: 16px !important;
  }
  .ant-picker-outlined.ant-picker-disabled,
  .ant-input-outlined.ant-input-disabled,
  .ant-input-outlined[disabled],
  .ant-picker .ant-picker-input > input[disabled] {
    background-color: var(--grey-600) !important;
    color: var(--light-grey-color) !important;
    font-size: 16px;
  }
  .ant-picker {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .ant-form-vertical .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-form-color);
  }
  .ant-form-vertical .ant-form-item-label {
    padding-bottom: 4px;
  }
  .ant-picker .ant-picker-suffix > * {
    color: var(--text-default-color);
  }
`;

export const StyledTable = styled.div`
  .ant-table-wrapper .ant-table-thead > tr > th {
    background-color: #f2f4f6;
    font-weight: 700 !important;
    color: var(--text-default-color);
  }
  .ant-table-row {
    border-bottom: 1px solid var(--grey-400);
  }
  .ant-table-wrapper .ant-table-tbody-virtual .ant-table-cell {
    border-bottom: 0 !important;
  }
`;

export const StyledCustomDatePicker = styled.div`
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: var(--text-default-color) !important;
  }
`;

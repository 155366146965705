// search property
export const searchHolder = 'を入力';
export const code = '物件コード';
export const name = '物件名';
export const building_type = '建物種別';
export const full_address = '住所';
export const number_of_floors = '階建て';
export const total_apartments = '総戸数';
export const construct_at = '建築年月';
export const structure = '構造';
export const branch = '担当支店';
export const sale_man = '営業部担当者';
export const branch_director = '支店担当者';
export const note = '備考';

//property detail tab contract information
export const startDate = '契約開始日';
export const contractName = '契約名';
export const advertisingExpense = '受注金額合計';
export const intermediationFee = '発注金額合計';
export const payment = '支払';

import { Button, Form, Input } from 'core-ui';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'src/enums/routePath';
import { replacePathParams } from 'src/utils/common';
import { StyledScheduleDetail } from './styled';
import { useScheduleDetail, useScheduleHistories } from '../hooks/useSchedule';
import {
  MappingProgressLabel,
  MappingScheduleFieldsChange,
  ScheduleFieldsChange,
  ScheduleHistoriesType
} from 'src/enums/schedule';
import { dateHelper } from 'core-helpers';
import { DATE_FORMAT_11, DATE_FORMAT_3 } from 'src/constants/date';
import CustomForm from 'src/components/CustomForm';
import { IScheduleHistories } from 'src/models/schedule';
import { notifyMessage } from 'src/constants/message';
import Field from './components/Field';

type IScheduleDetail = {
  closeModal: () => void;
  id: string;
};

interface IHistories {
  time: string | null;
  content: string;
}

const ScheduleDetail = (props: IScheduleDetail) => {
  const { id, closeModal } = props;
  const [form] = Form.useForm();
  const [scheduleHistories, setScheduleHistories] = useState<IHistories[]>();
  const navigate = useNavigate();

  useScheduleDetail({
    id,
    onSuccess: (data) => {
      let schedule = data?.data?.data;
      form.setFieldsValue({
        ...schedule,
        status:
          MappingProgressLabel[
            schedule?.status as keyof typeof MappingProgressLabel
          ],
        property_code: schedule?.property[0]?.code || '',
        property_name: schedule?.property[0]?.name || '',
        scheduled_at: dateHelper.formatDate({
          date: schedule?.scheduled_at,
          format: DATE_FORMAT_3
        }),
        performed_at: schedule?.performed_at
          ? dateHelper.formatDate({
              date: schedule?.performed_at,
              format: DATE_FORMAT_3
            })
          : undefined,
        facility_management_item: schedule?.facility_management_item || '',
        person_in_charge: schedule?.person_in_charge || '',
        vendor: schedule?.vendor || '',
        note: schedule?.note || '',
        item_order_company: schedule?.item_order_company || ''
      });
    }
  });

  useScheduleHistories({
    params: {
      schedule_id: id
    },
    onSuccess: (data) => {
      const histories = data?.data?.data?.map((item: IScheduleHistories) => {
        return handleRenderScheduleHistories(item);
      });
      setScheduleHistories(histories);
    }
  });

  const handleRenderScheduleHistories = (data: IScheduleHistories) => {
    const history_type = data?.history_type;
    const time: string | null = dateHelper.formatDate({
      date: data?.occur_at,
      format: DATE_FORMAT_11
    });
    const username = data?.action_by?.name || '';
    const field_changed =
      MappingScheduleFieldsChange[
        data?.key as keyof typeof MappingScheduleFieldsChange
      ];
    let content: string;
    switch (history_type) {
      case ScheduleHistoriesType.CREATE_NEW_SCHEDULE:
        content = 'スケジュールが作成されました。';
        break;
      case ScheduleHistoriesType.ADD_NEW_FIELD:
        content = `${username}${username ? 'が' : ''}${field_changed}を登録しました。`;
        break;
      case ScheduleHistoriesType.UPDATE_VALUE:
        if (data?.key === ScheduleFieldsChange.STATUS) {
          content = `${username}${username ? 'が' : ''}${field_changed}を変更しました。\n ${MappingProgressLabel[data?.value!] || notifyMessage.empty}→${MappingProgressLabel[data?.change_value!] || notifyMessage.empty}`;
          break;
        }
        if (data?.key === ScheduleFieldsChange.PERFORMED_AT) {
          const old_value =
            dateHelper.formatDate({
              date: data?.value,
              format: DATE_FORMAT_3
            }) || '';
          const new_value =
            dateHelper.formatDate({
              date: data?.change_value,
              format: DATE_FORMAT_3
            }) || '';
          content = `${username}${username ? 'が' : ''}${field_changed}を変更しました。\n ${old_value || notifyMessage.empty}→${new_value || notifyMessage.empty}`;
          break;
        }
        content = `${username}${username ? 'が' : ''}${field_changed}を変更しました。\n ${data?.value || notifyMessage.empty}→${data?.change_value || notifyMessage.empty}`;
        break;
      default:
        content = 'スケジュールが作成されました。';
        break;
    }
    return { time, content };
  };

  const handleEditSchedule = () => {
    const id = form.getFieldValue('id');
    navigate(replacePathParams(RoutePath.EDIT_SCHEDULE, { id }));
    closeModal();
  };

  return (
    <StyledScheduleDetail className="schedule-detail px-[25px]">
      <CustomForm
        form={form}
        name="control-hooks"
        onFinish={handleEditSchedule}
        layout="vertical"
        disabled={false}
        initialValues={props}
      >
        <div className="grid grid-cols-3 gap-x-10">
          <Input name="property_code" label="物件コード" disabled />
          <Field label="物件名" value={form.getFieldValue(['property_name'])} />
          <Input name="status" label="ステータス" disabled />
          <Input name="scheduled_at" label="予定日" disabled />
          <Input
            name="performed_at"
            label="実施日"
            disabled
            className="w-[calc(50%-2.5rem/2)]"
            formItemClassName="!col-span-2"
          />
          <Field
            label="項目"
            value={form.getFieldValue(['facility_management_item'])}
          />
          <Field
            label="担当者"
            value={form.getFieldValue(['person_in_charge'])}
          />
          <Field
            label="発注先"
            value={form.getFieldValue(['item_order_company'])}
          />

          <Input
            inputType="textArea"
            name="note"
            label="備考"
            className="!h-[123px] w-full !col-span-3"
            disabled
            formItemClassName="col-span-3 mt-5"
          />
        </div>
        <div className="text-sm font-medium text-grey-500 mb-3">変更履歴</div>
        <div className="h-[164px] border border-grey-600 p-3 overflow-auto">
          {scheduleHistories?.map((item) => (
            <div className="border-b-[1px] border-grey-300 text-xs text-[var(--text-default-color)] py-[8px] px-[10px]">
              <p>{item.time}</p>
              <div className="whitespace-pre-line">{item.content}</div>
            </div>
          ))}
        </div>
        <div className="w-full flex justify-center">
          <Button
            htmlType="submit"
            type="primary"
            className="bg-primary-500 text-[16px] rounded-[4px] h-[44px] w-[240px] mt-[30px] mb-[47px] mx-auto"
          >
            スケジュールを編集する
          </Button>
        </div>
      </CustomForm>
    </StyledScheduleDetail>
  );
};

export default ScheduleDetail;

import { Form, FormProps } from 'core-ui';
import React, { memo } from 'react';
import { KeyCode } from 'src/enums/keyCode';
import { NodeName } from 'src/enums/nodeName';

const CustomForm = (props: FormProps) => {
  const handleKeyPress = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (
      event.key === KeyCode.ENTER &&
      (event.target as any)?.nodeName !== NodeName.TEXT_AREA
    ) {
      event.preventDefault();
    }
  };

  return (
    <Form
      scrollToFirstError={{
        behavior: 'smooth'
      }}
      onKeyPress={handleKeyPress}
      {...props}
    >
      {props.children as React.ReactNode}
    </Form>
  );
};

export default memo(CustomForm);

import { RoutePath } from 'src/enums/routePath';
import { replacePathParams } from 'src/utils/common';
import { apiRequest } from 'src/utils/api';
import {
  IProjectParams,
  ProjectCreateOrdersVariables,
  ProjectCreateQuotationVariables,
  ProjectsResult,
  ProjectsVariables,
  ProjectUpdateQuotationVariables
} from './schema';
import { ApiPath } from 'src/enums/apiPath';
import {
  IBill,
  IParamsSearchQuotations,
  IQueryParams
} from 'src/models/project';
import { lodash } from 'core-helpers';
import { trimStrings } from 'src/utils/validate';

class ProjectService {
  async list(params?: IQueryParams) {
    const queryParams = trimStrings({ ...params });
    return await apiRequest.get({
      url: ApiPath.PROJECTS,
      params: queryParams
    });
  }

  async detail(variables: ProjectsVariables) {
    return await apiRequest.get({
      url: ApiPath.PROJECTS + `/${variables.id}`
    });
  }

  async createQuotation(variables: ProjectCreateQuotationVariables) {
    return await apiRequest.post({
      url: ApiPath.PROJECTS + `/${variables.id}/quotations/`,
      data: lodash.omit(variables, ['id'])
    });
  }

  async updateQuotation(variables: ProjectUpdateQuotationVariables) {
    return await apiRequest.put({
      url:
        ApiPath.PROJECTS +
        `/${variables.projectId}/quotations/${variables.quotationId}`,
      data: variables
    });
  }

  async update(variables: IProjectParams) {
    return await apiRequest.put({
      url: ApiPath.PROJECTS + `/${variables.id}`,
      data: variables
    });
  }

  async create(variables: IProjectParams) {
    return await apiRequest.post({
      url: ApiPath.PROJECTS,
      data: variables
    });
  }
  async delete(id: string) {
    return await apiRequest.delete({
      url: `${ApiPath.PROJECTS}/${id}`
    });
  }

  async searchQuotations(params: IParamsSearchQuotations) {
    const queryParams = trimStrings(params);
    return await apiRequest.get({
      url: RoutePath.PROJECT_SEARCH_QUOTATION,
      params: queryParams
    });
  }
  async getListItems(value: string = '') {
    return await apiRequest.get({
      url: RoutePath.PROJECT_SUGGESTION_ITEMS,
      params: { search: value }
    });
  }
  async getListTitle(value: string = '') {
    return await apiRequest.get({
      url: RoutePath.PROJECT_SUGGESTION_TITLE,
      params: { search: value }
    });
  }
  async detailQuotation(projectId?: string, quotationId?: string) {
    return await apiRequest.get({
      url: replacePathParams(RoutePath.PROJECT_QUOTATION_DETAIL, {
        projectId,
        quotationId
      })
    });
  }
  async deleteQuotation(projectId?: string, quotationId?: string) {
    return await apiRequest.delete({
      url: replacePathParams(RoutePath.PROJECT_QUOTATION_DETAIL, {
        projectId,
        quotationId
      })
    });
  }

  async searchOrders(params: IParamsSearchQuotations) {
    const queryParams = trimStrings(params);
    return await apiRequest.get({
      url: RoutePath.PROJECT_SEARCH_ORDER,
      params: queryParams
    });
  }

  async createOrder(variables: ProjectCreateOrdersVariables) {
    return (await apiRequest.post({
      url: ApiPath.PROJECTS + `/${variables.id}/orders/`,
      data: lodash.omit(variables, ['id'])
    })) as ProjectsResult;
  }

  async detailOrder(projectId?: string, orderId?: string) {
    return await apiRequest.get({
      url: replacePathParams(RoutePath.PROJECT_ORDER_DETAIL, {
        projectId,
        orderId
      })
    });
  }
  async deleteOrder(projectId?: string, orderId?: string) {
    return await apiRequest.delete({
      url: replacePathParams(RoutePath.PROJECT_ORDER_DETAIL, {
        projectId,
        orderId
      })
    });
  }

  async createBill(data: IBill) {
    return await apiRequest.post({
      url: replacePathParams(RoutePath.PROJECT_BILL, {
        projectId: data?.project_id
      }),
      data
    });
  }
  async updateBill(data: IBill) {
    return await apiRequest.put({
      url: replacePathParams(RoutePath.PROJECT_BILL_DETAIL, {
        projectId: data?.project_id,
        billId: data?.id
      }),
      data
    });
  }
  async deleteBill(projectId?: string, billId?: string) {
    return await apiRequest.delete({
      url: replacePathParams(RoutePath.PROJECT_BILL_DETAIL, {
        projectId,
        billId
      })
    });
  }
  async detailBill(projectId?: string, billId?: string) {
    return await apiRequest.get({
      url: replacePathParams(RoutePath.PROJECT_BILL_DETAIL, {
        projectId,
        billId
      })
    });
  }

  get project() {
    return {
      list: this.list,
      detail: this.detail,
      create: this.create,
      delete: this.delete,
      searchQuotations: this.searchQuotations,
      detailQuotation: this.detailQuotation,
      detailOrder: this.detailOrder,
      deleteOrder: this.deleteOrder,
      createBill: this.createBill,
      updateBill: this.updateBill,
      deleteBill: this.deleteBill,
      detailBill: this.detailBill
    };
  }
}

const projectService = new ProjectService();

export default projectService;

/* eslint-disable no-unused-vars */
export enum RoutePath {
  ALL = '/*',
  FORGOT_PASSWORD = '/forgot_password',
  LOGIN = '/login',
  NOT_FOUND = '/not-found',
  RESET_PASSWORD = '/reset_password',
  SIGN_UP = '/register',
  DASHBOARD = '/dashboard',

  CREATE_CONTRACT = '/list-property/:propertyId/create-contract',
  EDIT_CONTRACT = '/list-property/:propertyId/contract/:contractId',
  PROJECTS = '/projects',
  PROJECT_DETAIL = '/projects/:id',
  PROJECT_CREATE = '/projects/create',
  PROJECT_EDIT = '/projects/edit/:id',
  PROJECT_QUOTATION_CREATE = '/projects/:projectId/quotation/create',
  PROJECT_QUOTATION_DETAIL = '/projects/:projectId/quotations/:quotationId',
  PROJECT_QUOTATION_EDIT = '/projects/:projectId/quotation/edit/:quotationId',
  PROJECT_SEARCH_QUOTATION = '/projects/search/quotations',
  PROJECT_SEARCH_ORDER = '/projects/search/orders',
  PROJECT_ORDER_DETAIL = '/projects/:projectId/orders/:orderId',
  PROJECT_ORDER_CREATE = '/projects/:projectId/order/create',
  PROJECT_ORDER_EDIT = '/projects/:projectId/order/edit/:orderId',
  PROJECT_BILL = '/projects/:projectId/bills',
  PROJECT_BILL_DETAIL = '/projects/:projectId/bills/:billId',

  LIST_PROPERTY = '/list-property',
  ADD_PROPERTY = '/list-property/new',
  PROPERTY_DETAIL = '/list-property/:id',

  LIST_OWNER = '/list-owner',
  CREATE_NEW_OWNER = '/list-owner/create',
  EDIT_OWNER = '/list-owner/edit/:id',

  SCHEDULE = '/schedule',
  EDIT_SCHEDULE = '/schedule/edit/:id',

  BASIC_FEATURES = '/basic_function',
  ACCOUNT_MANAGEMENT = '/basic_function/account/management',
  CREATE_ACCOUNT = '/basic_function/account/create',
  EDIT_ACCOUNT = '/basic_function/account/edit/:id',
  SUGGESTION = '/basic_function/detail_suggestion/management',
  EDIT_SUGGESTION = '/basic_function/suggestion/edit',
  REMARK = '/basic_function/notes_template/management',
  REMARK_CREATE = '/basic_function/notes_template/create',
  REMARK_EDIT = '/basic_function/notes_template/edit/:id',

  ORDER_CREATE = '/projects/:projectId/orders',
  ORDER_DETAIL = '/projects/:projectId/orders/:orderId',
  ORDER_UPDATE = '/projects/:projectId/orders/:orderId',

  QUOTATION_CREATE = '/projects/:projectId/quotations',
  QUOTATION_DETAIL = '/projects/:projectId/quotations/:quotationId',
  QUOTATION_UPDATE = '/projects/:projectId/quotations/:quotationId',

  PROJECT_SUGGESTION_ITEMS = '/suggestion/items',
  PROJECT_SUGGESTION_TITLE = '/suggestion/titles'
}

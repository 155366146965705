import { Button, DatePicker, LeftOutlined, RightOutlined } from 'core-ui';
import { useState } from 'react';
import './index.scss';
import { dayjs } from 'core-helpers';
import { DATE_FORMAT_3, DATE_FORMAT_4 } from 'src/constants/date';

const DatePickerCustom = ({
  onChangeSelectedDate,
  selectedDate
}: {
  onChangeSelectedDate: (date: dayjs.Dayjs) => void;
  selectedDate: dayjs.Dayjs;
}) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const handleDateChange = (date: dayjs.Dayjs) => {
    onChangeSelectedDate(date);
    setShowPopup(false);
  };

  const handleButtonClick = () => {
    setShowPopup(!showPopup);
  };

  const formatDate = (date: dayjs.Dayjs) => {
    const startOfWeek = date.startOf('week');
    const endOfWeek = date.endOf('week');
    return `${startOfWeek.format(DATE_FORMAT_3)}〜${endOfWeek.format(DATE_FORMAT_4)}`;
  };

  const handleMonthChange = (direction: 'prev' | 'next') => {
    onChangeSelectedDate(
      selectedDate.add(direction === 'prev' ? -1 : 1, 'week')
    );
  };

  return (
    <div className="date-picker-custom">
      <div className="flex gap-x-3 items-center justify-center mt-10">
        <Button
          className="w-[28px] flex justify-center items-center"
          onClick={() => handleMonthChange('prev')}
        >
          <LeftOutlined />
        </Button>
        <Button
          className="text-[24px] flex items-center justify-center weight-700 text-[var(--text-default-color)]"
          onClick={handleButtonClick}
        >
          {formatDate(selectedDate)}
        </Button>
        <Button
          className="w-[28px] flex justify-center items-center"
          onClick={() => handleMonthChange('next')}
        >
          <RightOutlined />
        </Button>
      </div>
      <DatePicker open={showPopup} onChange={handleDateChange} />
    </div>
  );
};

export default DatePickerCustom;

import { BrowserRouter as Router } from 'react-router-dom';
import { renderRoutes, routes } from './routes';
import ErrorBoundary from 'src/components/ErrorBoundary';
import ModalProvider from 'src/contexts/modalContext';
import DrawerProvider from 'src/contexts/drawerContext';

const AppRouter = () => {
  return (
    <Router>
      <ModalProvider>
        <DrawerProvider>
          <ErrorBoundary>{renderRoutes(routes)}</ErrorBoundary>
        </DrawerProvider>
      </ModalProvider>
    </Router>
  );
};

export default AppRouter;

import { createSlice } from '@reduxjs/toolkit';
import { IPropertyDetail2 } from 'src/models/property';
import { useAppDispatch, useAppSelector } from '../hooks';
import { RootState } from '../store';
import ReduxSliceNames from 'src/constants/redux-slice-names';

interface IOwnerState {
  listProperty?: IPropertyDetail2[];
  tempListProperty?: IPropertyDetail2[];
}

const initialState: IOwnerState = {
  listProperty: [],
  tempListProperty: []
};

export const ownerSlice = createSlice({
  name: ReduxSliceNames.Owner,
  initialState,
  reducers: {
    setListPropertyState: (
      state: IOwnerState,
      action: { payload: { listProperty: IPropertyDetail2[] } }
    ) => {
      state.listProperty = action.payload.listProperty;
    },
    setTempListPropertyState: (
      state: IOwnerState,
      action: { payload: { tempListProperty: IPropertyDetail2[] } }
    ) => {
      state.tempListProperty = action.payload.tempListProperty;
    }
  }
});

export const { setListPropertyState, setTempListPropertyState } =
  ownerSlice.actions;

export const useGetPropertyRedux: () => IOwnerState = () =>
  useAppSelector(
    (state: RootState) => state[ReduxSliceNames.Owner]
  ) as IOwnerState;

export const useSetPropertyRedux = () => {
  const dispatch = useAppDispatch();

  return {
    setListProperty: (list: IPropertyDetail2[]) =>
      dispatch(setListPropertyState({ listProperty: list })),
    setTempListProperty: (list: IPropertyDetail2[]) =>
      dispatch(setTempListPropertyState({ tempListProperty: list }))
  };
};
export default ownerSlice;

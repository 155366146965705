export const TABLE_SCROLL_OFFSET = {
  x: 800,
  y: 500
};

export const TABLE_SCROLL_OFFSET_2 = {
  x: 1200,
  y: 900
};

export const TABLE_SCROLL_OFFSET_3 = {
  x: 1000,
  y: 'auto'
};

export const GET_ALL_LIMIT = -1;

import { createSlice } from '@reduxjs/toolkit';
import { IProperty } from 'src/models/property';
import { useAppDispatch, useAppSelector } from '../hooks';
import { RootState } from '../store';
import ReduxSliceNames from 'src/constants/redux-slice-names';

interface IProjectState {
  choseProperty?: IProperty;
  tempChoseProperty?: IProperty;
}

export const initialDataProperty = {
  id: '',
  code: '',
  postal: {},
  name: '',
  ownerName: '',
  ownerKataName: ''
};

const initialState: IProjectState = {
  choseProperty: initialDataProperty,
  tempChoseProperty: initialDataProperty
};

export const projectSlice = createSlice({
  name: ReduxSliceNames.Project,
  initialState,
  reducers: {
    setTempChosePropertyState: (
      state: IProjectState,
      action: { payload: { tempChoseProperty: IProperty } }
    ) => {
      state.tempChoseProperty = action.payload.tempChoseProperty;
    },
    setChosePropertyState: (
      state: IProjectState,
      action: { payload: { choseProperty: IProperty } }
    ) => {
      state.choseProperty = action.payload.choseProperty;
    }
  }
});

export const { setChosePropertyState, setTempChosePropertyState } =
  projectSlice.actions;

export const useGetChosePropertyRedux: () => IProjectState = () =>
  useAppSelector(
    (state: RootState) => state[ReduxSliceNames.Project]
  ) as IProjectState;

export const useSetChosePropertyRedux = () => {
  const dispatch = useAppDispatch();

  return {
    setChoseProperty: (property: IProperty) =>
      dispatch(setChosePropertyState({ choseProperty: property })),
    setTempChoseProperty: (property: IProperty) =>
      dispatch(setTempChosePropertyState({ tempChoseProperty: property }))
  };
};
export default projectSlice;

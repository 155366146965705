export const PROPERTY_KEY = 'property';
export const PROPERTY_QUOTATION_KEY = 'property_quotation';
export const PROPERTY_BILL_KEY = 'property_bills';
export const PROPERTY_DETAIL_KEY = 'property_detail';
export const POSTAL_CODE_KEY = 'address_by_postal_code';
export const POSTAL_CODE_KEY_2 = 'postal_code_by_address';
export const CITIES_KEY = 'cites';
export const OWNER_KEY = 'owner';
export const OWNER_DETAIL_KEY = 'owner_detail';
export const FIND_OWNER_KEY = 'find_owner';
export const ACCOUNT_KEY = 'account';
export const PROFILE_KEY = 'profile';
export const ACCOUNT_DETAIL_KEY = 'account_detail';
export const PROJECT_KEY = 'projects';
export const PROJECT_QUOTATION_KEY = 'project_quotation';
export const PROJECT_SUGGESTION_ITEMS = 'suggestion_items';
export const PROJECT_SUGGESTION_TITLE = 'suggestion_title';
export const PROJECT_BILL_KEY = 'project_bill';
export const PROJECT_SEARCH_QUOTATION = 'project_search_quotation';
export const PROJECT_SEARCH_ORDER = 'project_search_order';
export const PROJECT_ORDER_KEY = 'project_order';
export const TEMPLATE_KEY = 'template';
export const TEMPLATE_DETAIL_KEY = 'template_detail';
export const SCHEDULE_MAINTAIN = 'schedule_maintenance';
export const DASHBOARD_QUOTATION = 'dashboard_quotation';
export const SCHEDULE_KEY = 'schedule';
export const SCHEDULE_WEEK_KEY = 'schedule_week';
export const SCHEDULE_HISTORIES_KEY = 'schedule_histories';
export const NOTIFICATION_KEY = 'notification';
export const ITEM_SUGGESTION_KEY = 'item_suggestion';
export const TITLE_SUGGESTION_KEY = 'title_suggestion';

/* eslint-disable @typescript-eslint/no-explicit-any */
import { jsCookie } from 'core-helpers';
import { CookieKeys } from 'src/enums/cookie';

export const getCookie = (name: string) => jsCookie.get(name);

export const setCookie = (name: string, value: string, expires = 1) =>
  jsCookie.set(name, value, { expires });

export const removeCookie = (name: string) => jsCookie.remove(name);

export const clearCookies = () => {
  Object.values(CookieKeys).forEach((key) => jsCookie.remove(key));
};

import { MfortIcon } from 'src/assets/icons/MfortIcon';

const Logo = ({ collapsed }: { collapsed: boolean }) => {
  return (
    <div className="flex items-center flex-nowrap line-clamp-1 gap-x-[10px] text-[20px] justify-center h-[100%] w-full border-b-[1px]">
      {!collapsed ? (
        <div className="flex gap-x-[10px] items-center">
          <MfortIcon />
          <div className="font-[700] text-base text-nowrap">
            エムフォート 不動産管理
          </div>
        </div>
      ) : (
        <div className="flex items-center">
          <MfortIcon />
        </div>
      )}
    </div>
  );
};

export default Logo;

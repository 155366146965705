import { SVGProps } from 'react';

export const Svg = (props: SVGProps<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="27"
      viewBox="0 0 28 27"
      fill="none"
    >
      <path
        d="M4.77468 0L0.0369873 26.9286H1.66847L6.40577 0H4.77468Z"
        fill="white"
      />
      <path
        d="M18.8728 0L15.055 12.5745L15.268 5.72475C15.3697 3.52744 15.4292 1.61919 15.4459 0H6.98293L2.24524 26.9286H7.77554L10.9085 9.16463L10.3476 26.9286H14.6788L20.3093 8.74884L17.1181 26.9286H23.2256L27.9629 0H18.8728Z"
        fill="white"
      />
    </svg>
  );
};

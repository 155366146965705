const Header = ({ children }: { children: string }) => {
  return (
    <div
      className={`text-base font-medium ${children === '日' ? 'text-warning-300' : children === '土' ? 'text-primary-100' : 'text-[var(--light-grey-color)] '}`}
    >
      {children}
    </div>
  );
};

export default Header;

import { AxiosResponse } from 'core-api';
import { jsCookie } from 'core-helpers';
import { useMutation } from 'react-query';
import {
  ACCOUNT_DETAIL_KEY,
  ACCOUNT_KEY,
  PROFILE_KEY,
  PROJECT_KEY
} from 'src/constants/queryKeys';
import { CookieKeys } from 'src/enums/cookie';
import { useCustomQuery } from 'src/hooks/useCustomQuery';
import { IAccount, IOptionAccount } from 'src/models/account';
import { ApiResponseData } from 'src/models/apiResponse';
import {
  createAccount,
  updateAccount,
  deleteAccount,
  getListAccount,
  getAccountById,
  getProfile,
  logout
} from 'src/services/account';
import projectService from 'src/services/project';

interface IHandleAccount {
  onSuccess?: (data: AxiosResponse<ApiResponseData<IAccount[]>>) => void;
  onError?: (error: any) => void;
}

export const useAccount = () => {
  const { data, isLoading, error, refetch } = useCustomQuery(
    [ACCOUNT_KEY],
    () => getListAccount()
  );
  const dataAccount: IOptionAccount[] = data?.data?.data?.map(
    (item: IAccount) => {
      return {
        value: item?.id,
        label: item?.name
      };
    }
  );
  return {
    optionAccounts: dataAccount,
    accounts: data?.data?.data,
    total_items: data?.data?.meta?.total_items,
    isLoading,
    error,
    refetch
  };
};

export const useOptionAccount = (id: string) => {
  const { data: dataAccounts } = useCustomQuery([ACCOUNT_KEY], () =>
    getListAccount()
  );
  const { data: dataPersonInCharge } = useCustomQuery(
    [PROJECT_KEY, id],
    async () => {
      const result = await projectService.detail({ id });
      return result?.data?.data;
    },
    {
      enabled: !!id
    }
  );
  const dataAccount: IOptionAccount[] =
    dataAccounts?.data?.data?.map((item: IAccount) => {
      return {
        value: item?.id,
        label: item?.name
      };
    }) || [];

  const person_in_charge = dataPersonInCharge?.person_in_charge;

  const hasPersonInCharge = dataAccount.some(
    (account) => account.value === person_in_charge?.id
  );

  if (!hasPersonInCharge && !!person_in_charge) {
    dataAccount.push({
      value: person_in_charge?.id || '',
      label: person_in_charge?.name || ''
    });
  }

  return dataAccount || [];
};

export const useAccountDetail = (id: string) => {
  const { data, isLoading } = useCustomQuery(
    [ACCOUNT_DETAIL_KEY, id],
    () => getAccountById(id),
    {
      enabled: !!id
    }
  );
  return {
    detailAccount: data?.data?.data,
    isLoading
  };
};

export const useCreateAccount = (props: IHandleAccount) => {
  const { onSuccess, onError } = props;
  const result = useMutation((data: IAccount) => createAccount(data), {
    onSuccess,
    onError
  });
  return result;
};

export const useUpdateAccount = (props: IHandleAccount) => {
  const { onSuccess, onError } = props;
  const result = useMutation((data: IAccount) => updateAccount(data), {
    onSuccess,
    onError
  });
  return result;
};

export const useDeleteAccount = (props: IHandleAccount) => {
  const { onSuccess, onError } = props;
  const result = useMutation((id: string) => deleteAccount(id), {
    onSuccess,
    onError
  });
  return result;
};

export const useProfile = ({
  onSuccess
}: {
  onSuccess?: (data: AxiosResponse<ApiResponseData<IAccount>>) => void;
}) => {
  const { data, isLoading } = useCustomQuery(
    [PROFILE_KEY],
    () => getProfile(),
    {
      enabled: !!jsCookie.get(CookieKeys.ACCESS_TOKEN_KEY),
      onSuccess
    }
  );
  return {
    profile: data?.data?.data,
    isLoading
  };
};

export const useLogout = (props: IHandleAccount) => {
  const { onSuccess, onError } = props;
  const result = useMutation(() => logout(), {
    onSuccess,
    onError
  });
  return result;
};

import { Table, styled } from 'core-ui';

export const StyledTable = styled(Table)`
  .ant-table-container {
    border-color: var(--grey-600) !important;
    .ant-table-cell {
      border-color: var(--grey-600) !important;
      font-size: 14px !important;
      color: var(--text-default-color);
    }
  }
  .ant-table-wrapper .ant-table-column-sorter,
  .ant-table-wrapper .ant-table-column-sorter-inner {
    color: var(--text-default-color);
  }
  .th.ant-table-cell {
    font-weight: 500 !important;
    color: var(--text-default-color);
  }
  .long-text-content-ellipsis {
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  .ant-empty-description {
    font-size: 16px;
  }

  td > p {
    min-height: 22px !important;
  }
`;

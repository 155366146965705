import { Badge, DownOutlined, Popover } from 'core-ui';
import AdminAva from 'src/assets/images/admin-ava.png';
import { resultMessage } from 'src/constants/message';
import { RoutePath } from 'src/enums/routePath';
import useHandleNetwork from 'src/hooks/useHandleNetwork';
import { useNotification } from 'src/hooks/useNotification';
import { useLogout } from 'src/pages/BasicFunction/Account/hooks/useAccount';
import { useGetAuthState } from 'src/redux/auth/authSlice';
import { clearCookies } from 'src/utils/cookies';

const Header = () => {
  const { notify } = useNotification();

  const logout = useLogout({
    onError: () => {
      notify.error({ message: resultMessage.logout_failed });
    }
  });

  const handleLogout = async () => {
    await logout.mutateAsync();
    clearCookies();
    window.location.href = RoutePath.LOGIN;
  };

  const { isOnline } = useHandleNetwork();

  const { profile } = useGetAuthState();

  return (
    <div
      className="h-[100%] flex items-center justify-end px-[20px] bg-[#f8f8f8] text-for-all"
      style={{ borderBottom: '2px solid #f8f8f8' }}
    >
      <div className="flex">
        <Popover
          placement="bottomRight"
          content={
            <div className="min-w-[100px]">
              <p
                onClick={handleLogout}
                className="cursor-pointer w-full text-[16px]"
                style={{ fontWeight: '400' }}
              >
                ログアウト
              </p>
            </div>
          }
        >
          <div className="flex cursor-pointer items-center">
            <div className="bg-white rounded-[50%] p-[3px]">
              <img src={AdminAva} alt="" />
            </div>

            <div className="mr-[20px] flex">
              <Badge
                dot={false}
                color="green"
                offset={[14, 10]}
                style={{
                  display: isOnline ? 'block' : 'none',
                  height: '10px',
                  width: '10px'
                }}
              >
                <div className="flex flex-col ml-[8px]">
                  <p
                    className="leading-[20px] font-[400] text-[16px] min-w-[60px] text-primary-200"
                    style={{ fontFamily: '"Noto Sans JP", sans-serif' }}
                  >
                    {profile?.name}
                  </p>
                </div>
              </Badge>
            </div>

            <DownOutlined
              style={{
                color: 'black',
                fontSize: '14px'
              }}
            />
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default Header;

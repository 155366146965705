import { notification } from 'core-ui';
import { useNavigate } from 'react-router-dom';
import { ReactNode } from 'react';

const notificationTypes = ['success', 'error', 'warning'] as const;

type NotificationType = (typeof notificationTypes)[number];

export type Notification = Record<
  NotificationType,
  ({ message, path }: { message: string | ReactNode; path?: string }) => void
>;

export const useNotification = () => {
  const navigate = useNavigate();

  const createNotification = (type: NotificationType) => {
    return {
      [type]: ({
        message,
        path
      }: {
        message: string | ReactNode;
        path?: string;
      }) => {
        notification[type]({
          message:
            typeof message === 'string' ? (
              <p className="whitespace-nowrap">{message}</p>
            ) : (
              message
            ),
          duration: 3
        });
        if (path) {
          navigate(path);
        }
      }
    };
  };

  const notify: Notification = notificationTypes.reduce((acc, type) => {
    Object.assign(acc, createNotification(type));

    return acc;
  }, {} as Notification);

  return {
    notify
  };
};

import {
  ContractItem,
  MappingProgressLabel,
  Progress
} from 'src/enums/schedule';

export const MappingContractItemLabel = {
  [ContractItem.REGULAR_CLEANING]: '定期清掃',
  [ContractItem.BUILDING_PATROL]: '建物巡回',
  [ContractItem.WATERWORKS_INSPECTION]: '水道検診',
  [ContractItem.FIRE_INSPECTION]: '消防点検',
  [ContractItem.EV_INSPECTION]: 'EV点検',
  [ContractItem.WATER_TANK_CLEANING]: '貯水槽清掃',
  [ContractItem.PUMP_INSPECTION]: 'ポンプ点検',
  [ContractItem.SEPTIC_TANK_MAINTENANCE]: '浄化槽保守',
  [ContractItem.DRAINAGE_PIPES]: '排水管',
  [ContractItem.OTHERS]: 'その他'
};

export const optionProgress = [
  {
    label: MappingProgressLabel[Progress.NOT_CONFIRMED],
    value: Progress.NOT_CONFIRMED
  },
  {
    label: MappingProgressLabel[Progress.NOT_COORDINATED],
    value: Progress.NOT_COORDINATED
  },
  {
    label: MappingProgressLabel[Progress.COORDINATED],
    value: Progress.COORDINATED
  },
  {
    label: MappingProgressLabel[Progress.COMPLETED],
    value: Progress.COMPLETED
  },
  {
    label: MappingProgressLabel[Progress.REPORT_RECEIVED],
    value: Progress.REPORT_RECEIVED
  },
  {
    label: MappingProgressLabel[Progress.PDF_SENT],
    value: Progress.PDF_SENT
  },
  {
    label: MappingProgressLabel[Progress.ORIGINAL_SENT],
    value: Progress.ORIGINAL_SENT
  },
  {
    label: MappingProgressLabel[Progress.ALL],
    value: Progress.ALL
  }
];

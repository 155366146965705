const ProjectSvg = () => (
  <svg
    width="20"
    height="15"
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M1.86047 14.9423C1.34884 14.9423 0.910853 14.7601 0.546512 14.3958C0.182171 14.0315 0 13.5935 0 13.0818V1.91906C0 1.40743 0.182171 0.969446 0.546512 0.605105C0.910853 0.240764 1.34884 0.0585938 1.86047 0.0585938H7.44186L9.30233 1.91906H16.7442C17.2558 1.91906 17.6938 2.10123 18.0581 2.46557C18.4225 2.82991 18.6047 3.2679 18.6047 3.77952H8.53488L6.67442 1.91906H1.86047V13.0818L4.09302 5.63999H20L17.6047 13.6167C17.4806 14.0198 17.2519 14.3415 16.9186 14.5818C16.5853 14.8222 16.2171 14.9423 15.814 14.9423H1.86047ZM3.81395 13.0818H15.814L17.4884 7.50045H5.48837L3.81395 13.0818Z"
    />
  </svg>
);

export default ProjectSvg;

export const notifyMessage = {
  success: '成功!',
  failed: '失敗した!',
  empty: '空'
};

export const resultMessage = {
  create: '作成が完了しました。',
  edit: '修正が完了しました。',
  delete: '削除が完了しました。',
  createError: '作成が失敗しました。',
  editError: '修正が失敗しました。',
  deleteError: '削除が失敗しました。',
  create_failed: '作成が失敗しました。',
  edit_failed: '修正が失敗しました。',
  delete_failed: '削除が失敗しました。',
  logout_failed: 'ログアウトが失敗しました。'
};

export const confirmMessage = {
  confirmLinkOwner: '取引先情報が変更されています。このまま保存しますか？'
};

export const ResponseMessage = {
  EMAIL_EXIST: 'このメールアドレスは既にアカウントに登録されています。'
};

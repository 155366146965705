import { Progress } from 'src/enums/schedule';

interface TagProps {
  label: string;
  variants: (typeof TAG_VARIANT)[keyof typeof TAG_VARIANT];
}

export const TAG_VARIANT = {
  DESTRUCTIVE: 'destructive',
  WARNING: 'warning'
};

const TAG_VARIANTS_COLOR = {
  [TAG_VARIANT.WARNING]: 'bg-warning-400',
  [TAG_VARIANT.DESTRUCTIVE]: 'bg-warning-500',
  [Progress.NOT_CONFIRMED]: '!bg-[var(--color-not-confirm)]',
  [Progress.NOT_COORDINATED]: '!bg-[var(--color-not-coordinated)]',
  [Progress.COORDINATED]: '!bg-[var(--color-coordinated)]',
  [Progress.COMPLETED]: '!bg-[var(--color-completed)]',
  [Progress.REPORT_RECEIVED]: '!bg-[var(--color-report-received)]',
  [Progress.PDF_SENT]: '!bg-[var(--color-pdf-sent)]',
  [Progress.ORIGINAL_SENT]: '!bg-[var(--color-original-sent)]'
};

const Tag = ({ label, variants }: TagProps) => {
  return (
    <div
      className={`text-white rounded-[3px] ${TAG_VARIANTS_COLOR[variants]} text-xs font-[500] h-[23px] w-fit !px-[10px] flex items-center justify-center`}
    >
      <p>{label}</p>
    </div>
  );
};

export default Tag;

import React, { Fragment, Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PrivateRoute } from 'src/components/PrivateRoute';
import { RoleGuard } from 'src/components/RolePrivateRoute';
import { RoutePath } from 'src/enums/routePath';
import { UserRole } from 'src/enums/userRole';
import MainLayout from 'src/layouts/MainLayout';

const LoginPage = lazy(() => import('../pages/Login'));
const DashboardPage = lazy(() => import('../pages/DashboardPage'));
const ListPropertyPage = lazy(() => import('../pages/Property/ListProperty'));
const ListProjectPage = lazy(() => import('../pages/Project/List'));
const ListOwnerPage = lazy(() => import('../pages/Owner/ListOwner'));
const AddOwnerPage = lazy(() => import('../pages/Owner/AddOwner'));
const ForgotPasswordPage = lazy(() => import('../pages/ForgotPassword'));
const ResetPasswordPage = lazy(() => import('../pages/ResetPassword'));
const ProjectDetailPage = lazy(() => import('../pages/Project/Detail'));
const ProjectCreatePage = lazy(() => import('../pages/Project/Create'));
const ProjectEditPage = lazy(() => import('../pages/Project/Edit'));
const ProjectQuotationCreatePage = lazy(
  () => import('../pages/Project/Quotation/Create')
);
const ProjectQuotationDetailPage = lazy(
  () => import('../pages/Project/Quotation/Detail')
);
const ProjectQuotationEditPage = lazy(
  () => import('../pages/Project/Quotation/Edit')
);
const ProjectOrderDetailPage = lazy(
  () => import('../pages/Project/Order/Detail')
);
const ProjectOrderEditPage = lazy(() => import('../pages/Project/Order/Edit'));
const ProjectOrderCreatePage = lazy(
  () => import('../pages/Project/Order/Create')
);
const ProjectBillDetailPage = lazy(
  () => import('../pages/Project/Bill/Detail')
);
const AddPropertyPage = lazy(() => import('../pages/Property/AddProperty'));
const PropertyDetailPage = lazy(
  () => import('../pages/Property/PropertyDetail')
);
const SchedulePage = lazy(() => import('../pages/Schedule'));
const EditSchedule = lazy(() => import('../pages/Schedule/EditSchedule'));
const BasicFunctionPage = lazy(() => import('../pages/BasicFunction'));
const AccountManagePage = lazy(() => import('../pages/BasicFunction/Account'));
const AddAccountPage = lazy(
  () => import('../pages/BasicFunction/Account/AddAccount')
);
const SuggestionPage = lazy(() => import('../pages/BasicFunction/Suggestion'));
const EditSuggestionPage = lazy(
  () => import('../pages/BasicFunction/Suggestion/EditSuggestion')
);
const RemarkPage = lazy(() => import('../pages/BasicFunction/Remark'));
const AddRemarkPage = lazy(
  () => import('../pages/BasicFunction/Remark/AddRemark')
);
const CreateContract = lazy(() => import('../pages/Property/CreateContract'));
const NotFoundPage = lazy(() => import('../pages/NotFound'));

export interface IRoutesState {
  exact?: boolean;
  path?: string;
  Guard?: React.ElementType;
  Layout?: React.ElementType;
  Component?: React.ElementType;
  routes?: IRoutesState[];
}

export const renderRoutes = (routes: IRoutesState[]) => (
  <Suspense fallback={null}>
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.Guard || Fragment;
        const Layout = route.Layout || Fragment;
        const Component = route.Component || Fragment;

        return (
          <Route
            key={i}
            path={route.path}
            element={
              <Guard>
                <Layout>
                  {route.routes ? renderRoutes(route.routes) : <Component />}
                </Layout>
              </Guard>
            }
          />
        );
      })}
    </Routes>
  </Suspense>
);

const adminRoutes = [
  {
    path: RoutePath.ACCOUNT_MANAGEMENT,
    Component: AccountManagePage,
    allowedRoles: [UserRole.ADMIN]
  },
  {
    path: RoutePath.CREATE_ACCOUNT,
    Component: AddAccountPage,
    allowedRoles: [UserRole.ADMIN]
  },
  {
    path: RoutePath.EDIT_ACCOUNT,
    Component: AddAccountPage,
    allowedRoles: [UserRole.ADMIN]
  }
];

export const routes: IRoutesState[] = [
  {
    path: RoutePath.LOGIN,
    Component: LoginPage
  },
  {
    path: RoutePath.FORGOT_PASSWORD,
    Component: ForgotPasswordPage
  },
  {
    path: RoutePath.RESET_PASSWORD,
    Component: ResetPasswordPage
  },
  {
    path: RoutePath.NOT_FOUND,
    Component: NotFoundPage
  },
  {
    path: RoutePath.ALL,
    Layout: MainLayout,
    Guard: PrivateRoute,
    routes: [
      {
        path: '/',
        Component: () => <Navigate to={RoutePath.DASHBOARD} replace />
      },
      {
        path: RoutePath.DASHBOARD,
        Component: DashboardPage
      },
      {
        path: RoutePath.LIST_PROPERTY,
        Component: ListPropertyPage
      },
      {
        path: RoutePath.PROPERTY_DETAIL,
        Component: PropertyDetailPage
      },
      {
        path: RoutePath.CREATE_CONTRACT,
        Component: CreateContract
      },

      {
        path: RoutePath.EDIT_CONTRACT,
        Component: CreateContract
      },

      {
        path: RoutePath.ADD_PROPERTY,
        Component: AddPropertyPage
      },
      {
        path: RoutePath.PROJECTS,
        Component: ListProjectPage
      },
      {
        path: RoutePath.PROJECT_DETAIL,
        Component: ProjectDetailPage
      },
      {
        path: RoutePath.PROJECT_CREATE,
        Component: ProjectCreatePage
      },
      {
        path: RoutePath.PROJECT_EDIT,
        Component: ProjectEditPage
      },
      {
        path: RoutePath.PROJECT_QUOTATION_CREATE,
        Component: ProjectQuotationCreatePage
      },
      {
        path: RoutePath.LIST_OWNER,
        Component: ListOwnerPage
      },
      {
        path: RoutePath.CREATE_NEW_OWNER,
        Component: AddOwnerPage
      },
      {
        path: RoutePath.EDIT_OWNER,
        Component: AddOwnerPage
      },
      {
        path: RoutePath.SCHEDULE,
        Component: SchedulePage
      },
      {
        path: RoutePath.EDIT_SCHEDULE,
        Component: EditSchedule
      },
      {
        path: RoutePath.BASIC_FEATURES,
        Component: BasicFunctionPage
      },
      {
        path: RoutePath.SUGGESTION,
        Component: SuggestionPage
      },
      {
        path: RoutePath.EDIT_SUGGESTION,
        Component: EditSuggestionPage
      },
      {
        path: RoutePath.REMARK,
        Component: RemarkPage
      },
      {
        path: RoutePath.REMARK_CREATE,
        Component: AddRemarkPage
      },
      {
        path: RoutePath.REMARK_EDIT,
        Component: AddRemarkPage
      },
      {
        path: RoutePath.PROJECT_QUOTATION_DETAIL,
        Component: ProjectQuotationDetailPage
      },
      {
        path: RoutePath.PROJECT_QUOTATION_EDIT,
        Component: ProjectQuotationEditPage
      },
      {
        path: RoutePath.PROJECT_ORDER_DETAIL,
        Component: ProjectOrderDetailPage
      },
      {
        path: RoutePath.PROJECT_ORDER_EDIT,
        Component: ProjectOrderEditPage
      },
      {
        path: RoutePath.PROJECT_ORDER_CREATE,
        Component: ProjectOrderCreatePage
      },
      {
        path: RoutePath.PROJECT_BILL_DETAIL,
        Component: ProjectBillDetailPage
      },
      ...adminRoutes.map((route) => ({
        path: route.path,
        Component: route.Component,
        Guard: (props: { children: React.ReactElement }) => (
          <RoleGuard allowedRoles={route.allowedRoles} {...props} />
        )
      })),
      {
        path: '*',
        Component: () => <Navigate to={RoutePath.NOT_FOUND} replace />
      }
    ]
  }
];

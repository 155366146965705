import { Spin } from 'core-ui';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { RoutePath } from 'src/enums/routePath';
import { useProfile } from 'src/pages/BasicFunction/Account/hooks/useAccount';
import { useSetAuthState } from 'src/redux/auth/authSlice';

interface RoleGuardProps {
  allowedRoles: string[];
  children: React.ReactElement;
}

export const RoleGuard: React.FC<RoleGuardProps> = ({
  allowedRoles,
  children
}) => {
  const { loginRedux, setProfile } = useSetAuthState();
  const { isLoading, profile } = useProfile({
    onSuccess: (data) => {
      setProfile(data?.data?.data);
      loginRedux();
    }
  });

  return isLoading ? (
    <div className="flex justify-center items-center h-[100vh]">
      <Spin size="large" />
    </div>
  ) : !profile || !allowedRoles.includes(String(profile?.permission_type)) ? (
    <Navigate to={RoutePath.NOT_FOUND} replace />
  ) : (
    children
  );
};

import { Button, CloseOutlined, Modal, twMerge } from 'core-ui';
import { useMemo, useState } from 'react';
import { ModalConfigI } from 'src/models/modal';

const defaultConfigs = {
  title: '',
  content: <></>,
  okeButtonLabel: '',
  cancelButtonLabel: '',
  footer: null,
  isShowFooter: true,
  modalProps: {
    width: 'calc((1000 / 1440) * 100vw)',
    className:
      '[&_.ant-modal-close]:hover:bg-[transparent] !top-[50px] !mb-[100px]',
    closeIcon: (
      <div className="mr-20 mt-5 pb-5 text-[30px] hover:!bg-[transparent] translate-x-[10px]">
        <CloseOutlined />
      </div>
    )
  },
  onOke: () => {},
  onCancel: () => {},
  onClose: () => {}
};

export const useRenderedModal = () => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [modalConfig, setModalConfig] = useState<ModalConfigI>(defaultConfigs);

  const modalProps = useMemo(() => {
    return {
      ...modalConfig.modalProps,
      className: twMerge('left-[125px]', modalConfig?.modalProps?.className)
    };
  }, [modalConfig]);

  const openModal = (configs?: ModalConfigI) => {
    setModalConfig({
      ...defaultConfigs,
      ...configs,
      modalProps: {
        ...defaultConfigs.modalProps,
        ...configs?.modalProps,
        className: twMerge(
          defaultConfigs.modalProps.className,
          configs?.modalProps?.className
        )
      }
    });

    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
    modalConfig.onClose?.();
  };

  const handleCancel = () => {
    closeModal();
    modalConfig?.onCancel?.();
  };

  const renderModal = ({ content }: { content: React.ReactNode }) => {
    return (
      <Modal
        centered
        open={isOpenModal}
        onCancel={closeModal}
        footer={modalConfig?.footer}
        {...modalProps}
      >
        <p className="text-center text-primary-600 mb-[8px]">
          {modalConfig?.title}
        </p>

        <div>{content}</div>

        {modalConfig?.isShowFooter && (
          <div
            className={twMerge(
              'flex pt-[8px] px-[16px] gap-[10px] justify-center mt-[32px]',
              modalConfig?.footerClassName
            )}
          >
            <Button
              style={{
                width: '147px',
                height: '36px',
                fontSize: '16px'
              }}
              onClick={handleCancel}
              className={modalConfig?.cancelButtonClassName}
            >
              {modalConfig?.cancelButtonLabel || ''}
            </Button>
            <Button
              style={{
                width: '147px',
                height: '36px',
                fontSize: '16px',
                ...modalConfig?.okeButtonStyle
              }}
              onClick={modalConfig?.onOke}
              className={modalConfig?.okeButtonClassName}
            >
              {modalConfig?.okeButtonLabel || ''}
            </Button>
          </div>
        )}
      </Modal>
    );
  };

  return {
    isOpenModal,
    modalProps,
    modalConfig,
    handleCancel,
    openModal,
    closeModal,
    renderModal
  };
};

import { Button } from 'core-ui';
import { ReactNode } from 'react';

interface ICustomOperationButtonProps {
  icon: ReactNode;
  text: string;
  className?: string;
  leftToRight?: boolean;
  handleClick?: () => void;
  disabled?: boolean;
}
const CustomOperationButton = (props: ICustomOperationButtonProps) => {
  const {
    icon,
    text,
    className,
    leftToRight = true,
    handleClick,
    disabled = false
  } = props;
  return (
    <Button
      htmlType="button"
      type="text"
      className={`w-[50px] h-[25px] flex items-center justify-center rounded-[3px] text-[12px] border ${className}`}
      onClick={handleClick}
      rootClassName={`hover:${className}`}
      disabled={disabled}
    >
      <div
        className={`flex ${!leftToRight ? 'flex-row-reverse' : ''} items-center justify-center gap-1 font-[500]`}
      >
        {icon}
        {text}
      </div>
    </Button>
  );
};

export default CustomOperationButton;

import { Fragment, useState } from 'react';
import {
  Button,
  CheckboxPropsI,
  DatePicker,
  DatePickerPropsI,
  DownOutlined,
  Form,
  Input,
  InputPropsI,
  RadioPropsI,
  Select,
  SelectPropsI
} from 'core-ui';
import { StyledSearchForm } from './styled';
import useSubscribeEvent from 'src/hooks/useSubscribeEvent';
import { EventType } from 'src/enums/event';
import { trimStrings } from 'src/utils/validate';
import CustomForm from '../CustomForm';

export enum FieldType {
  INPUT = 'input',
  SELECT = 'select',
  DATE_PICKER = 'datePicker',
  CHECKBOX = 'checkbox',
  RADIO = 'radio'
}

export type FormField = DatePickerPropsI &
  InputPropsI &
  SelectPropsI &
  RadioPropsI &
  CheckboxPropsI & {
    fieldType: `${FieldType}`;
    isRange?: boolean;
  };

type ExtendedSearchFormI = {
  search?: string;
};

interface SearchFormProps<SearchFormI> {
  onSearch?: (formData: SearchFormI) => void;
  onResetForm?: () => void;
  formFields: FormField[];
}

const SearchForm = <SearchFormI,>(props: SearchFormProps<SearchFormI>) => {
  const { formFields, onSearch, onResetForm } = props;
  const [isExtend, setIsExtend] = useState<boolean>(false);
  const [form] = Form.useForm<SearchFormI & ExtendedSearchFormI>();

  const renderFormFields = () => {
    return formFields?.map((field, index) => {
      if (field?.fieldType === FieldType.INPUT) {
        return <Input key={index} {...field} className="form-input" />;
      }

      if (field?.fieldType === FieldType.SELECT) {
        return <Select key={index} {...field} className="form-input" />;
      }

      if (field?.fieldType === FieldType.DATE_PICKER) {
        return (
          <DatePicker
            key={index}
            {...field}
            className="form-input w-full"
            formItemClassName={`${field?.isRange ? 'range' : ''} ${field.formItemClassName}`}
          />
        );
      }

      return null;
    });
  };

  const handleSubmitSearch = (formData: ExtendedSearchFormI & SearchFormI) => {
    onSearch?.(
      trimStrings({
        ...formData,
        search: isExtend ? undefined : formData?.search
      })
    );
  };

  const handleResetFields = () => {
    form.resetFields();
    onResetForm?.();
  };

  const toggleExtend = () => {
    form.resetFields();

    setIsExtend(!isExtend);
  };

  useSubscribeEvent([
    {
      type: EventType.CLEAR_FORM,
      listener: handleResetFields
    }
  ]);

  return (
    <StyledSearchForm>
      <CustomForm
        form={form}
        name="control-hooks"
        onFinish={handleSubmitSearch}
        className="bg-grey-200 p-[24px] w-full text-[var(--text-default-color)]"
        layout="vertical"
      >
        <div className="flex flex-row flex-wrap lg:flex-nowrap justify-between">
          <p className="text-[18px] font-bold whitespace-nowrap lg:w-[25%] mt-1">
            絞り込み検索
          </p>

          <div className="flex flex-row lg:w-[75%] 2xl:flex-nowrap lg:flex-nowrap">
            <p className="m-2 whitespace-nowrap">フリーワード</p>
            <div className="lg:w-[85%]">
              <Input
                disabled={isExtend}
                name="search"
                placeholder="検索したいキーワードを入力"
                className="w-[160px] lg:w-full text-base h-[39px]"
              />
            </div>

            <div className="flex text-[14px] mt-2">
              <span className="ml-[20px] cursor-pointer" onClick={toggleExtend}>
                <div className="flex whitespace-nowrap gap-[5px]">
                  <p>詳細検索</p>
                  <DownOutlined
                    style={{
                      transform: `${isExtend ? 'rotate(0deg)' : 'rotate(180deg)'}`,
                      transition: 'transform 0.5s'
                    }}
                  />
                </div>
              </span>
            </div>
          </div>
        </div>

        {isExtend && (
          <Fragment>
            <div className="border-b-[1px] border-grey-600"></div>
            <div className="mt-[20px] ">
              <div className="w-full grid lg:grid-cols-[23%_23%_23%_1fr] grid-cols-2 gap-x-[16px]">
                {renderFormFields()}
              </div>
            </div>
          </Fragment>
        )}

        <div className="flex justify-end gap-[10px] mb-[9px] mt-[6px]">
          <Button
            type="text"
            onClick={handleResetFields}
            className="text-[var(--light-grey-color)] h-[40px] w-[160px] rounded-[54px] p-[10px] bg-[#EEEEEE] border border-grey-400"
          >
            クリア
          </Button>

          <Button
            htmlType="submit"
            type="primary"
            className="bg-primary-500 h-[40px] w-[160px] rounded-[54px] p-[10px]"
            color="#fff"
          >
            検索
          </Button>
        </div>
      </CustomForm>
    </StyledSearchForm>
  );
};

export default SearchForm;

import { createContext } from 'react';
import { useRenderedModal } from 'src/hooks/useRenderedModal';
import { ModalConfigI } from 'src/models/modal';

interface ModalProviderPropsI {
  children: React.ReactNode;
}

export const ModalContext = createContext({
  openModal: (_config?: ModalConfigI) => {},
  closeModal: () => {},
  isOpenModal: false
});

const ModalProvider = (props: ModalProviderPropsI) => {
  const { children } = props;
  const { isOpenModal, modalConfig, openModal, closeModal, renderModal } =
    useRenderedModal();

  return (
    <ModalContext.Provider value={{ isOpenModal, openModal, closeModal }}>
      {children}
      {renderModal({ content: modalConfig?.content })}
    </ModalContext.Provider>
  );
};

export default ModalProvider;

import { dateHelper, dayjs } from 'core-helpers';
import { ArrowRightOutlined, Select, Table } from 'core-ui';
import { useEffect, useMemo, useState } from 'react';
import { DATE_FORMAT_1, DATE_FORMAT_6 } from 'src/constants/date';
import {
  GET_ALL_LIMIT,
  TABLE_SCROLL_OFFSET,
  TABLE_SCROLL_OFFSET_3
} from 'src/constants/common';
import ScheduleDetail from './ScheduleDetail';
import DatePickerCustom from './components/DatePickerCustom';
import MonthPickerCustom from './components/MonthPickerCustom';
import {
  getSevenDayLater,
  getToday,
  handleFilterDataForCalendar,
  handleFilterDataForCalendarWeek
} from 'src/utils/calendar';
import { MappingProgressLabel, Progress } from 'src/enums/schedule';
import CustomOperationButton from 'src/components/CustomOperationButton';
import CustomSubHeader from 'src/components/CustomSubHeader';
import Tag from 'src/components/Tag';
import {
  StyledCalendar,
  StyledCustomDatePicker,
  StyledFormItem,
  StyledTable
} from './styled';
import { useSchedule, useScheduleWeek } from './hooks/useSchedule';
import { ISchedule, IScheduleArr } from 'src/models/schedule';
import {
  MappingContractItemLabel,
  optionProgress
} from 'src/constants/schedule';
import { useRenderedModal } from 'src/hooks/useRenderedModal';
import CustomTable from 'src/components/CustomTable';
import {
  getCalendarRange,
  getListDateBetweenARangeOfDates,
  getRangeOfWeek
} from 'src/utils/date';
import ScheduleItem from './components/ScheduleItem';
import DateNumber from './components/DateNumber';
import Header from './components/Header';
import RefineSearch from '../Property/ListProperty/components/RefineSearch';
import { useSearchParams } from 'react-router-dom';

export enum ViewEnum {
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day'
}

type View = `${ViewEnum}`;

const Schedule = () => {
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs>(dayjs());
  const [view, setView] = useState<View>(ViewEnum.MONTH);
  const [dataSchedulesOfOneWeek, setDataSchedulesOfOneWeek] =
    useState<ISchedule[]>();
  const [dataCalendar, setDataCalendar] = useState<IScheduleArr[]>();
  const [dataCalendarWeek, setDataCalendarWeek] = useState<IScheduleArr[]>();
  const [status, setStatus] = useState<string>(Progress.ALL);
  const [param, setParam] = useState({});
  const [searchParams] = useSearchParams();
  const scheduleId = searchParams.get('scheduleId');

  const { isLoading } = useSchedule({
    params: {
      ...param,
      scheduled_from: getCalendarRange(selectedDate).startDate,
      scheduled_to: getCalendarRange(selectedDate).endDate,
      status: status !== Progress.ALL ? Number(status) : undefined,
      limit: GET_ALL_LIMIT
    },
    onSuccess: (data) => {
      const temp: ISchedule[] = data?.data?.data?.map((item) => {
        return {
          ...item,
          contract_item_content: String(item?.contract_item_content),
          status: String(item.status)
        };
      });
      setDataCalendar(handleFilterDataForCalendar(temp, daysShowedInCalendar));
    }
  });

  const { isLoading: isLoadingCalendarWeek } = useScheduleWeek({
    params: {
      ...param,
      scheduled_from: getRangeOfWeek(selectedDate).startDate,
      scheduled_to: getRangeOfWeek(selectedDate).endDate,
      status: status !== Progress.ALL ? Number(status) : undefined,
      limit: GET_ALL_LIMIT
    },
    onSuccess: (data) => {
      const temp: ISchedule[] = data?.data?.data?.map((item) => {
        return {
          ...item,
          contract_item_content: String(item?.contract_item_content),
          status: String(item.status)
        };
      });
      setDataCalendarWeek(
        handleFilterDataForCalendarWeek(
          temp,
          daysShowedInCalendar,
          daysShowedInCalendar.dateMonYear.indexOf(
            dayjs(selectedDate).format(DATE_FORMAT_6)
          )
        )
      );
    }
  });

  const { isLoading: loadingScheduleOfOneWeek } = useSchedule({
    params: {
      scheduled_from: getToday(),
      scheduled_to: getSevenDayLater(),
      limit: GET_ALL_LIMIT
    },
    onSuccess: (data) => {
      const temp: ISchedule[] = data?.data?.data?.map((item) => {
        return {
          ...item,
          contract_item_content: String(item?.contract_item_content),
          status: String(item.status)
        };
      });
      setDataSchedulesOfOneWeek(temp);
    }
  });

  const columnScheduleMonth = [
    {
      title: '日付',
      dataIndex: 'scheduled_at',
      width: '17%',
      key: 'scheduled_at',
      render: (text: string) => {
        return (
          <div>
            <p>
              {dateHelper.formatDate({
                date: text,
                format: DATE_FORMAT_1
              })}
            </p>
          </div>
        );
      }
    },
    {
      title: 'ステータス',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      render: (text: number) => (
        <Tag
          label={MappingProgressLabel[String(text)]}
          variants={String(text)}
        />
      )
    },
    {
      title: '内容',
      width: '50%',
      render: (text: string, record: ISchedule) => {
        return (
          <div className="flex">
            <div className="max-w-[80%] overflow-hidden text-ellipsis">
              {record?.property_name}
            </div>
            {MappingContractItemLabel[record?.contract_item_content]}
          </div>
        );
      }
    },
    {
      title: '操作',
      width: '10%',
      render: (text: string, record: ISchedule) => {
        return (
          <div>
            <CustomOperationButton
              text="確認"
              leftToRight={false}
              icon={<ArrowRightOutlined />}
              className="!text-primary-400 border-primary-400"
              handleClick={() => handleViewDetailSchedule(record.id)}
            />
          </div>
        );
      }
    }
  ];

  const { renderModal, openModal, closeModal } = useRenderedModal();
  const [idSchedule, setIdSchedule] = useState<string>('');

  const daysShowedInCalendar = useMemo(() => {
    return {
      dates: getListDateBetweenARangeOfDates(selectedDate).date,
      dateMonYear: getListDateBetweenARangeOfDates(selectedDate).dateMonYear
    };
  }, [selectedDate]);

  const createColumn = (
    dayIndex: number,
    title: string,
    dataIndex: keyof IScheduleArr
  ) => ({
    dataIndex: dataIndex,
    key: String(dayIndex),
    title: <Header>{title}</Header>,
    onCell: () => ({
      style: {
        verticalAlign: 'top',
        padding: '15px 15px 15px 8px'
      }
    }),
    render: (_: any, data: IScheduleArr, index: number) => {
      return (
        <div>
          <DateNumber>
            {daysShowedInCalendar?.dates[index * 7 + dayIndex]}
          </DateNumber>
          {data?.[dataIndex]?.map((schedule, i) => (
            <div onClick={() => handleViewDetailSchedule(schedule?.id)} key={i}>
              <ScheduleItem schedule={schedule} />
            </div>
          ))}
        </div>
      );
    }
  });

  const columns = [
    createColumn(0, '日', 'sun'),
    createColumn(1, '月', 'mon'),
    createColumn(2, '火', 'tue'),
    createColumn(3, '水', 'wed'),
    createColumn(4, '木', 'thu'),
    createColumn(5, '金', 'fri'),
    createColumn(6, '土', 'sat')
  ];

  const handleChangeView = (view: View) => {
    setView(view);
  };

  const handleChangeSelectedDate = (date: dayjs.Dayjs) => {
    setSelectedDate(date);
  };

  const handleChangeStatus = (value: string) => {
    setStatus(value);
  };

  const handleViewDetailSchedule = (id: string) => {
    setIdSchedule(id);
    openModal({
      title: (
        <div className="text-[black] text-[18px] font-[700] text-left border-b-[1px] border-grey-600 mx-5 my-5 pb-4">
          スケジュール詳細
        </div>
      ),
      onOke: () => {
        closeModal();
      },
      onCancel: () => {
        closeModal();
      },
      isShowFooter: false
    });
  };

  useEffect(() => {
    if (scheduleId) {
      handleViewDetailSchedule(scheduleId);
    }
  }, [scheduleId]);

  return (
    <>
      {renderModal({
        content: <ScheduleDetail closeModal={closeModal} id={idSchedule} />
      })}

      <CustomSubHeader title="スケジュール管理" />

      <div className="mt-[32px] mb-[42px]">
        <StyledTable>
          <CustomTable
            virtual
            loading={loadingScheduleOfOneWeek}
            className="text-for-all whitespace-nowrap"
            dataSource={dataSchedulesOfOneWeek}
            columns={columnScheduleMonth}
            scroll={TABLE_SCROLL_OFFSET}
          />
        </StyledTable>
      </div>

      <StyledCustomDatePicker>
        {view === ViewEnum.WEEK ? (
          <DatePickerCustom
            onChangeSelectedDate={handleChangeSelectedDate}
            selectedDate={selectedDate}
          />
        ) : (
          <MonthPickerCustom
            onChangeSelectedDate={handleChangeSelectedDate}
            selectedDate={selectedDate}
          />
        )}
      </StyledCustomDatePicker>

      <div className="mt-[-20px] mb-[24px]">
        <div className="flex gap-x-[30px] !text-grey-500">
          <div className="w-[320px]">
            <div className="mb-1 font-medium text-grey-500">進捗状況</div>
            <StyledFormItem>
              <Select
                name="progress"
                options={optionProgress}
                defaultValue={Progress.ALL}
                className="h-[39px] text-base"
                onChange={handleChangeStatus}
              />
            </StyledFormItem>
          </div>
          <div>
            <div>
              <div className="mb-1 font-medium text-grey-500">
                カレンダー表示
              </div>
              <div className="flex text-base rounded-[4px] font-medium">
                <div
                  onClick={() => handleChangeView(ViewEnum.WEEK)}
                  className={`rounded-[4px] rounded-r-none cursor-pointer hover:bg-grey-600 border h-[39px] px-5 flex items-center justify-center ${view === ViewEnum.WEEK ? 'bg-grey-600' : ''}`}
                >
                  週
                </div>
                <div
                  onClick={() => handleChangeView(ViewEnum.MONTH)}
                  className={`rounded-[4px] rounded-l-none border-l-0 cursor-pointer hover:bg-grey-600 border h-[39px] px-5 flex items-center justify-center ${view === ViewEnum.MONTH ? 'bg-grey-600' : ''}`}
                >
                  月
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <RefineSearch setParam={setParam} />

      <StyledCalendar>
        {view === ViewEnum.MONTH ? (
          <CustomTable
            loading={isLoading}
            columns={columns}
            dataSource={dataCalendar}
            bordered
            scroll={TABLE_SCROLL_OFFSET_3}
            className="mt-[66px]"
          />
        ) : (
          <CustomTable
            loading={isLoadingCalendarWeek}
            columns={columns}
            dataSource={dataCalendarWeek}
            bordered
            scroll={TABLE_SCROLL_OFFSET_3}
            className="mt-[66px]"
          />
        )}
      </StyledCalendar>
    </>
  );
};

export default Schedule;

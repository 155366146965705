import { REMOVE_LEADING_ZEROS } from 'src/constants/regex';
import { StyledScheduleItem } from './styled';

const DateNumber = ({ children }: { children: string }) => {
  return (
    <>
      <div className="absolute top-2 left-3 text-[21px] font-[500] text-[#969696]">
        <StyledScheduleItem>
          {children?.replace(REMOVE_LEADING_ZEROS, '')}
        </StyledScheduleItem>
      </div>
      <div className="mb-8"></div>
    </>
  );
};

export default DateNumber;

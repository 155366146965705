import { styled } from 'core-ui';

export const StyledScheduleDetail = styled.div`
  label {
    color: var(--text-form-color) !important;
  }

  .ant-table-cell {
    border-color: var(--grey-600) !important;
  }

  .ant-input {
    height: 43px;
    border: none;
    border-bottom: 1px solid var(--grey-600);
    border-radius: 0px;
    color: var(--text-default-color);
    font-size: 16px;
  }

  .ant-input:focus {
    border: none;
    border-bottom: 1px solid var(--grey-600);
    border-radius: 0px;
    box-shadow: none;
  }

  .ant-input-disabled {
    color: var(--text-form-color) !important;
    background: #fff !important;
  }

  .ant-select-disabled {
    background: #fff !important;
  }

  .ant-select-selector {
    border: none !important;
    border-bottom: 1px solid var(--grey-600) !important;
    border-radius: 0px;
    background: #fff !important;
  }

  .ant-input-affix-wrapper {
    border: none;
    border-bottom: 1px solid var(--grey-600);
    border-radius: 0px;
  }

  .ant-input-affix-wrapper:focus {
    border: none;
    border-bottom: 1px solid var(--grey-600);
    border-radius: 0px;
    box-shadow: none;
  }

  .ant-form-vertical .ant-form-item-label {
    color: var(--text-form-color) !important;
    font-weight: 500;
  }
`;

import ApiRequest, { InternalAxiosRequestConfig } from 'core-api';
import { jsCookie } from 'core-helpers';
import { BASE_API_URL } from 'src/constants/services';
import { ApiPath } from 'src/enums/apiPath';
import { CookieKeys } from 'src/enums/cookie';
import { RoutePath } from 'src/enums/routePath';
import { MappingApiRoute, UserRole } from 'src/enums/userRole';
import { logout } from 'src/services/auth';

const handleCookieError = (url: string) => {
  const isMissingCookies = Object.values(CookieKeys).some(
    (key) => !jsCookie.get(key)
  );

  if (isMissingCookies && url !== ApiPath.LOGIN) {
    return logout();
  }
};

export const apiRequest = new ApiRequest({
  baseUrl: BASE_API_URL,
  accessTokenKey: CookieKeys.ACCESS_TOKEN_KEY,
  interceptorRequestConfig: (config: InternalAxiosRequestConfig) => {
    handleCookieError(config.url as string);
    const userRole = jsCookie.get(CookieKeys.USER_ROLE_KEY);
    const isAuthRoute = [
      ApiPath.LOGIN,
      ApiPath.LOGOUT,
      ApiPath.PROFILE
    ].includes(config.url as ApiPath);

    const apiRoute = isAuthRoute
      ? ''
      : MappingApiRoute[userRole as keyof typeof MappingApiRoute] ||
        MappingApiRoute[UserRole.USER];

    config.baseURL = `${BASE_API_URL}api/v1/${apiRoute}`;

    return config;
  },
  onNotFound: () => {
    window.location.replace(RoutePath.NOT_FOUND);
  },
  onUnauthenticated: () => logout()
});

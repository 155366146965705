import { coreDayjsLocalizer } from 'core-calendar';
import { dateHelper, dayjs } from 'core-helpers';
import { DATE_FORMAT_6 } from 'src/constants/date';
import { ViewEnum } from 'src/pages/Schedule';
import { compareDatesDayjs } from './date';
import { ISchedule, IScheduleArr } from 'src/models/schedule';

export const customLocalizer = {
  ...coreDayjsLocalizer,

  formats: {
    ...coreDayjsLocalizer.formats,
    dateFormat: 'D'
  },
  format(value: Date, formatString: string, locale: string) {
    const formatted = coreDayjsLocalizer.format(value, formatString, locale);

    return formatted
      .replace('Mon', '月')
      .replace('Tue', '火')
      .replace('Wed', '水')
      .replace('Thu', '木')
      .replace('Fri', '金')
      .replace('Sat', '土')
      .replace('Sun', '日');
  }
};

export const getFirstDayOfMonth = (date: dayjs.Dayjs) => {
  const firstDayOfMonth = date.startOf(ViewEnum.MONTH);
  return dateHelper.formatDate({
    date: firstDayOfMonth,
    format: DATE_FORMAT_6
  });
};

export const getLastDayOfMonth = (date: dayjs.Dayjs) => {
  const lastDayOfMonth = date.endOf(ViewEnum.MONTH);
  return dateHelper.formatDate({
    date: lastDayOfMonth,
    format: DATE_FORMAT_6
  });
};

export const getToday = () => {
  const date = dayjs();
  return dateHelper.formatDate({
    date,
    format: DATE_FORMAT_6
  });
};

export const getSevenDayLater = () => {
  const date = dayjs().add(7, 'days');
  return dateHelper.formatDate({
    date,
    format: DATE_FORMAT_6
  });
};

const initialScheduleOfOneWeek: () => IScheduleArr = () => {
  return {
    sun: [],
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: []
  };
};

export const handleFilterDataForCalendar = (
  data: ISchedule[],
  daysShowedInCalendar: {
    dateMonYear: string[];
  }
) => {
  let scheduleArr: IScheduleArr[] = [];
  const processedSchedule = new Set();

  const numberOfRowsShowInCalendar =
    daysShowedInCalendar?.dateMonYear?.length / 7;

  for (let i = 0; i < numberOfRowsShowInCalendar; i++) {
    scheduleArr[i] = initialScheduleOfOneWeek();

    const lastWeek = dayjs(daysShowedInCalendar.dateMonYear[i * 7 + 6]);

    for (const schedule of data) {
      const schedule_at = dayjs(schedule.scheduled_at);

      if (compareDatesDayjs(schedule_at, lastWeek)) {
        break;
      }

      if (processedSchedule.has(schedule)) continue;

      const week = schedule_at.day();
      const dayKeys = [
        'sun',
        'mon',
        'tue',
        'wed',
        'thu',
        'fri',
        'sat'
      ] as const;

      scheduleArr[i][dayKeys[week]].push(schedule);
      processedSchedule.add(schedule);
    }
  }

  return scheduleArr;
};

export const handleFilterDataForCalendarWeek = (
  data: ISchedule[],
  daysShowedInCalendar: {
    dateMonYear: string[];
  },
  week: number
) => {
  const i = Math.floor(week / 7);
  let scheduleArr: IScheduleArr[] = [];
  const processedSchedule = new Set();

  scheduleArr[i] = initialScheduleOfOneWeek();

  const lastWeek = dayjs(daysShowedInCalendar.dateMonYear[i * 7 + 6]);

  for (const schedule of data) {
    const schedule_at = dayjs(schedule.scheduled_at);

    if (compareDatesDayjs(schedule_at, lastWeek)) {
      break;
    }

    if (processedSchedule.has(schedule)) continue;

    const week = schedule_at.day();
    const dayKeys = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'] as const;

    scheduleArr[i][dayKeys[week]].push(schedule);
    processedSchedule.add(schedule);
  }

  return scheduleArr;
};

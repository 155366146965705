export const DATE_FORMAT_1 = 'YYYY/MM/DD (ddd)';
export const DATE_FORMAT_2 = 'YYYY年/MM月';
export const DATE_FORMAT_3 = 'YYYY年MM月DD日';
export const DATE_FORMAT_4 = 'DD日';
export const DATE_FORMAT_5 = 'YYYY年MM月';
export const DATE_FORMAT_6 = 'YYYY-MM-DD';
export const DATE_FORMAT_7 = 'YYYY-MM-DD HH:mm';
export const DATE_FORMAT_8 = 'YYYY/MM/DD';
export const DATE_FORMAT_9 = 'yyyy-MM-dd';
export const DATE_FORMAT_10 = 'YYYY/MM/DD HH:mm';
export const DATE_FORMAT_11 = 'YYYY/MM/DD HH:mm:ss';
export const DATE_FORMAT_12 = 'YYYY年MM月';
export const DATE_FORMAT_13 = 'YYYY-MM';

export const Svg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M7.3 20.5L6.9 17.3C6.68333 17.2167 6.47917 17.1167 6.2875 17C6.09583 16.8833 5.90833 16.7583 5.725 16.625L2.75 17.875L0 13.125L2.575 11.175C2.55833 11.0583 2.55 10.9458 2.55 10.8375V10.1625C2.55 10.0542 2.55833 9.94167 2.575 9.825L0 7.875L2.75 3.125L5.725 4.375C5.90833 4.24167 6.1 4.11667 6.3 4C6.5 3.88333 6.7 3.78333 6.9 3.7L7.3 0.5H12.8L13.2 3.7C13.4167 3.78333 13.6208 3.88333 13.8125 4C14.0042 4.11667 14.1917 4.24167 14.375 4.375L17.35 3.125L20.1 7.875L17.525 9.825C17.5417 9.94167 17.55 10.0542 17.55 10.1625V10.8375C17.55 10.9458 17.5333 11.0583 17.5 11.175L20.075 13.125L17.325 17.875L14.375 16.625C14.1917 16.7583 14 16.8833 13.8 17C13.6 17.1167 13.4 17.2167 13.2 17.3L12.8 20.5H7.3ZM9.05 18.5H11.025L11.375 15.85C11.8917 15.7167 12.3708 15.5208 12.8125 15.2625C13.2542 15.0042 13.6583 14.6917 14.025 14.325L16.5 15.35L17.475 13.65L15.325 12.025C15.4083 11.7917 15.4667 11.5458 15.5 11.2875C15.5333 11.0292 15.55 10.7667 15.55 10.5C15.55 10.2333 15.5333 9.97083 15.5 9.7125C15.4667 9.45417 15.4083 9.20833 15.325 8.975L17.475 7.35L16.5 5.65L14.025 6.7C13.6583 6.31667 13.2542 5.99583 12.8125 5.7375C12.3708 5.47917 11.8917 5.28333 11.375 5.15L11.05 2.5H9.075L8.725 5.15C8.20833 5.28333 7.72917 5.47917 7.2875 5.7375C6.84583 5.99583 6.44167 6.30833 6.075 6.675L3.6 5.65L2.625 7.35L4.775 8.95C4.69167 9.2 4.63333 9.45 4.6 9.7C4.56667 9.95 4.55 10.2167 4.55 10.5C4.55 10.7667 4.56667 11.025 4.6 11.275C4.63333 11.525 4.69167 11.775 4.775 12.025L2.625 13.65L3.6 15.35L6.075 14.3C6.44167 14.6833 6.84583 15.0042 7.2875 15.2625C7.72917 15.5208 8.20833 15.7167 8.725 15.85L9.05 18.5ZM10.1 14C11.0667 14 11.8917 13.6583 12.575 12.975C13.2583 12.2917 13.6 11.4667 13.6 10.5C13.6 9.53333 13.2583 8.70833 12.575 8.025C11.8917 7.34167 11.0667 7 10.1 7C9.11667 7 8.2875 7.34167 7.6125 8.025C6.9375 8.70833 6.6 9.53333 6.6 10.5C6.6 11.4667 6.9375 12.2917 7.6125 12.975C8.2875 13.6583 9.11667 14 10.1 14Z"
        fill="currentColor"
      />
    </svg>
  );
};

import {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  useQuery
} from 'react-query';

type QueryOptions<TQueryFnData> = Omit<
  UseQueryOptions<TQueryFnData, unknown, TQueryFnData, QueryKey>,
  'queryKey' | 'queryFn'
>;

export const useCustomQuery = <TQueryFnData = unknown>(
  key: QueryKey,
  queryFn: QueryFunction<TQueryFnData, QueryKey>,
  options: QueryOptions<TQueryFnData> = {}
) => {
  const newOptions: QueryOptions<TQueryFnData> = {
    refetchOnWindowFocus: false,
    ...options
  };

  return useQuery(key, queryFn, newOptions);
};

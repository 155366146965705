import { IAccount } from './../../models/account';
import { createSlice } from '@reduxjs/toolkit';
import ReduxSliceNames from 'src/constants/redux-slice-names';
import { IAuthRedux } from './models';
import { useAppDispatch, useAppSelector } from '../hooks';
import { RootState, store } from '../store';

const initialState: IAuthRedux = {
  isAuthenticated: false,
  profile: null
};

export const authSlice = createSlice({
  name: ReduxSliceNames.Auth,
  initialState,
  reducers: {
    setAuthState: (
      state: IAuthRedux = initialState,
      action: { payload: Partial<IAuthRedux> }
    ) => {
      return { ...state, ...action.payload };
    }
  }
});

const { setAuthState } = authSlice.actions;

export const useGetAuthState: () => IAuthRedux = () =>
  useAppSelector((state: RootState) => state[authSlice.name]);

export const useSetAuthState = () => {
  const dispatch = useAppDispatch();

  return {
    loginRedux: () => {
      return dispatch(setAuthState({ isAuthenticated: true }));
    },
    setProfile: (profile: IAccount) => {
      dispatch(setAuthState({ profile }));
    }
  };
};

export const handleLogoutRedux = () => {
  store.dispatch(setAuthState({ isAuthenticated: false, profile: null }));
};

import { styled } from 'core-ui';

export const StyledSearchForm = styled.div`
  .ant-form-item .ant-form-item-label > label {
    color: var(--text-default-color);
  }
  .ant-form-vertical .ant-form-item-label {
    padding-bottom: 6px !important;
  }
  .ant-form-item {
    margin-bottom: 18px;
  }
  .range {
    position: relative;

    &::after {
      content: '~';
      position: absolute;
      right: -14px;
      top: 32px;
      font-weight: 400;
      font-size: 20px;
    }
  }
  .ant-select .ant-select-selector,
  .ant-input-affix-wrapper,
  .ant-input {
    border-radius: 3px !important;
    font-size: 16px;
  }
  .ant-picker .ant-picker-input > input {
    font-size: 16px;
  }
`;

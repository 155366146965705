import { AxiosResponse } from 'core-api';
import { useMutation } from 'react-query';
import {
  SCHEDULE_HISTORIES_KEY,
  SCHEDULE_KEY,
  SCHEDULE_WEEK_KEY
} from 'src/constants/queryKeys';
import { useCustomQuery } from 'src/hooks/useCustomQuery';
import { ApiResponseData, ApiResponseListData } from 'src/models/apiResponse';
import {
  ISchedule,
  IScheduleHistories,
  IScheduleHistoriesParams,
  IScheduleParams
} from 'src/models/schedule';
import {
  deleteSchedule,
  getListSchedule,
  getListScheduleHistories,
  getScheduleById,
  updateSchedule
} from 'src/services/schedule';

interface IHandleSchedule {
  onSuccess?: (data: AxiosResponse<ApiResponseData<ISchedule[]>>) => void;
  onError?: (error: any) => void;
  params?: IScheduleParams;
}

interface IHandleScheduleHistories {
  onSuccess?: (
    data: AxiosResponse<ApiResponseListData<IScheduleHistories>>
  ) => void;
  onError?: (error: any) => void;
  params?: IScheduleHistoriesParams;
}

export const useSchedule = (props: IHandleSchedule) => {
  const { params, onSuccess, onError } = props;
  const { isLoading, error, refetch } = useCustomQuery(
    [SCHEDULE_KEY, params],
    () => getListSchedule(params),
    {
      onSuccess,
      onError
    }
  );
  return {
    isLoading,
    error,
    refetch
  };
};

export const useScheduleWeek = (props: IHandleSchedule) => {
  const { params, onSuccess, onError } = props;
  const { isLoading, error, refetch } = useCustomQuery(
    [SCHEDULE_WEEK_KEY, params],
    () => getListSchedule(params),
    {
      onSuccess,
      onError
    }
  );
  return {
    isLoading,
    error,
    refetch
  };
};

export const useScheduleDetail = ({
  id,
  onSuccess
}: {
  id: string;
  onSuccess?: (data: any) => void;
}) => {
  const { data, isLoading } = useCustomQuery(
    [SCHEDULE_KEY, id],
    () => getScheduleById(id),
    { onSuccess }
  );
  return {
    detailSchedule: data?.data?.data,
    isLoading
  };
};

export const useDeleteSchedule = (props: IHandleSchedule) => {
  const { onSuccess, onError } = props;
  const result = useMutation((id: string) => deleteSchedule(id), {
    onSuccess,
    onError
  });
  return result;
};

export const useUpdateSchedule = (props: IHandleSchedule) => {
  const { onSuccess, onError } = props;
  const result = useMutation((data: ISchedule) => updateSchedule(data), {
    onSuccess,
    onError
  });
  return result;
};

export const useScheduleHistories = (props: IHandleScheduleHistories) => {
  const { params, onSuccess, onError } = props;
  const { isLoading, error, refetch } = useCustomQuery(
    [SCHEDULE_HISTORIES_KEY, params?.schedule_id],
    () => getListScheduleHistories(params!),
    {
      onSuccess,
      onError
    }
  );
  return {
    isLoading,
    error,
    refetch
  };
};

export interface IProps {
  label: string;
  value: string;
}
const Field = (props: IProps) => {
  const { label, value } = props;
  return (
    <div className="text-[var(--text-form-color)]">
      <div className="mb-2 font-medium">{label}</div>
      <div className="h-[43px] border-b-[1px] border-[var(--grey-600)] flex items-end pb-2 text-base pl-2">
        <p className=" text-ellipsis max-w-full overflow-hidden whitespace-nowrap">
          {value}
        </p>
      </div>
    </div>
  );
};

export default Field;

export enum WarningMessage {
  KANJI_ROMAN = '漢字またはローマ字のみ入力してください。',
  KATAKANA = 'カタカナのみ入力してください。',
  PHONE = '入力された電話番号が正しくありません。電話番号は10桁または11桁の数字で入力してください。',
  POSTAL = '入力された郵便番号は間違っています。郵便番号は7桁の数字で入力してください。',
  REQUIRED = 'この項目は必須です。',
  EMAIL = '正しいメールアドレスを入力してください。\n(例: user@example.com)',
  NOT_ENOUGH_12 = 'パスワードは12桁以上で入力してください。',
  NOT_ENTER = '未入力です。',
  EXACT_LENGTH_13 = '13桁の数字を 入力してください',
  EXACT_LENGTH_10 = '入力内容が最大文字数を超えています。最大10文字以内で入力してください。',
  FAX = 'FAX番号の形式が正しく入力してください。',
  NUMBER = '数字のみを入力してください',
  CONFIRM_DELETE = '本当に削除しますか。',
  POSTAL_2 = '入力された郵便番号は間違っています。',
  ADDRESS = '入力された住所に対応する郵便番号が見つかりませんでした。',
  MAX_LENGTH_100 = '要件は100文字以内で入力してください。',
  START_DATE = '開始日を選択してください は有効期限より前の日付にしてください。',
  EXPECTED_END_DATE = '終了日は開始日より後の日付にしてください。',
  MAX_LENGTH = '入力内容が最大文字数を超えています。最大{max}文字以内で入力してください。',
  CREATE_OWNER_WITHOUT_PROPERTY = 'オーナーにはまだ物件が紐付けされていません。\nこのまま登録しますか？',
  REQUIRE_COMPLETE_AT = '完了日を入力してください。'
}

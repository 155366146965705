import { ConfigProvider, ja_JP } from 'core-ui';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import AppRouter from './router';

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: 0, refetchOnWindowFocus: false } }
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ConfigProvider locale={ja_JP}>
          <AppRouter />
        </ConfigProvider>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;

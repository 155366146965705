import { createRoot } from 'react-dom/client';
import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root')!;
const root = createRoot(container);

const setIndex = () => {
  const isProduction = process.env.REACT_APP_ENV === 'production';
  const meta = document.createElement('meta');
  meta.setAttribute('name', 'robots');
  meta.setAttribute('content', isProduction ? 'index,follow' : 'noindex');
  document.head.appendChild(meta);
};

setIndex();

root.render(<App />);

reportWebVitals();

import { MappingContractItemLabel } from 'src/constants/schedule';
import { MappingColor, MappingProgressLabel } from 'src/enums/schedule';
import { ISchedule } from 'src/models/schedule';
import { StyledScheduleItem } from './styled';
interface IProps {
  schedule: ISchedule;
}
const ScheduleItem = (props: IProps) => {
  const { schedule } = props;
  return (
    <div
      className={`cursor-pointer w-[95%] mt-[6px] custom-event ${MappingColor[schedule.status]} mx-1 my-0 p-[6px] rounded-[4px] mb-0 text-[var(--text-default-color)] text-sm font-medium w-full overflow-hidden text-ellipsis`}
    >
      <StyledScheduleItem>
        {schedule.property_name}
        {MappingContractItemLabel[schedule.contract_item_content]}
        <br /> [{MappingProgressLabel[schedule.status]}]
      </StyledScheduleItem>
    </div>
  );
};

export default ScheduleItem;

export const ListProperySvg = () => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 18.5V4.5H4V0.5H14V8.5H18V18.5H10V14.5H8V18.5H0ZM2 16.5H4V14.5H2V16.5ZM2 12.5H4V10.5H2V12.5ZM2 8.5H4V6.5H2V8.5ZM6 12.5H8V10.5H6V12.5ZM6 8.5H8V6.5H6V8.5ZM6 4.5H8V2.5H6V4.5ZM10 12.5H12V10.5H10V12.5ZM10 8.5H12V6.5H10V8.5ZM10 4.5H12V2.5H10V4.5ZM14 16.5H16V14.5H14V16.5ZM14 12.5H16V10.5H14V12.5Z"
      fill="currentColor"
    />
  </svg>
);

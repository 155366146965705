import {
  IS_VALID_POSTAL_CODE,
  IS_VALID_EMAIL,
  IS_VALID_PHONE_NUMBER,
  IS_VALID_EXACT_13_CHARS,
  IS_VALID_MIN_12_CHARS,
  IS_VALID_EXACT_100_CHARS,
  IS_VALID_NAME,
  IS_FULL_WHITESPACE,
  REMOVE_WHITESPACE,
  IS_NUMBER,
  IS_VALID_KATAKANA_SPACE,
  IS_VALID_MAX_10_CHARS
} from 'src/constants/regex';
import { WarningMessage } from 'src/enums/message';

const allowKeys = [
  'Backspace',
  'Delete',
  'ArrowUp',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'Space',
  'Tab',
  'Shift',
  'Caps Lock',
  'Ctrl'
];

export const validateFullWhitespace = (stringValue: string): boolean => {
  return IS_FULL_WHITESPACE.test(stringValue);
};

export const formatPhoneNumber = (value: string) => {
  let trimmed = value.replace(REMOVE_WHITESPACE, '').slice(0, 13);

  if (trimmed.length > 13) {
    trimmed = trimmed.slice(0, 13);
  }

  if (trimmed.length === 13) {
    trimmed = trimmed.replace(/-/g, '');

    let numbers = [];
    numbers.push(trimmed.slice(0, 3));

    let a = trimmed.slice(3, 7);
    let b = trimmed.slice(7, 11);

    if (a !== '') numbers.push(a);
    if (b !== '') numbers.push(b);

    return numbers.join('-');
  }

  trimmed = trimmed.replace(/-/g, '');

  let numbers = [];
  numbers.push(trimmed.slice(0, 3));

  let a = trimmed.slice(3, 6);
  let b = trimmed.slice(6, 10);

  if (a !== '') numbers.push(a);
  if (b !== '') numbers.push(b);

  return numbers.join('-');
};

export const formatPostalCode = (value: string) => {
  let trimmed = value.replace(REMOVE_WHITESPACE, '').slice(0, 8);

  if (trimmed.length > 8) {
    trimmed = trimmed.slice(0, 8);
  }

  trimmed = trimmed.replace(/-/g, '');

  let numbers = [];
  numbers.push(trimmed.slice(0, 3));

  let a = trimmed.slice(3, 7);
  if (a !== '') numbers.push(a);

  return numbers.join('-');
};

export const formatFax = (value: string) => {
  let trimmed = value.replace(REMOVE_WHITESPACE, '').slice(0, 12);

  if (trimmed.length > 12) {
    trimmed = trimmed.slice(0, 11);
  }

  trimmed = trimmed.replace(/-/g, '');

  let numbers = [];
  numbers.push(trimmed.slice(0, 2));

  let a = trimmed.slice(2, 6);
  let b = trimmed.slice(6, 10);
  if (a !== '') numbers.push(a);
  if (b !== '') numbers.push(b);

  return numbers.join('-');
};

export const handleEnterNumberOnly = (
  event: React.KeyboardEvent<HTMLInputElement>
) => {
  if (allowKeys.includes(event.key)) {
    return;
  }
  if (!event.ctrlKey && !IS_NUMBER.test(event.key)) {
    event.preventDefault();
  }
};

export const handleEnterName = (
  event: React.KeyboardEvent<HTMLInputElement>
) => {
  const key = event.key;
  if (allowKeys.includes(event.key)) {
    return;
  }
  if (!event.ctrlKey && !IS_VALID_NAME.test(key)) {
    event.preventDefault();
  }
};

export const handleEnterNameKatakana = (
  event: React.KeyboardEvent<HTMLInputElement>
) => {
  const key = event.key;
  if (allowKeys.includes(event.key)) {
    return;
  }
  if (!event.ctrlKey && !IS_VALID_KATAKANA_SPACE.test(key)) {
    event.preventDefault();
  }
};

export const isValidPostalCode = (postalCode: string) => {
  return IS_VALID_POSTAL_CODE.test(postalCode);
};

export const ruleName = [
  {
    pattern: IS_VALID_NAME,
    message: WarningMessage.KANJI_ROMAN
  }
];

export const ruleKatakana = [
  {
    pattern: IS_VALID_KATAKANA_SPACE,
    message: WarningMessage.KATAKANA
  }
];

export const ruleKatakanaSpace = [
  {
    pattern: IS_VALID_KATAKANA_SPACE,
    message: WarningMessage.KATAKANA
  }
];

export const rulePhone = [
  {
    required: true,
    message: WarningMessage.REQUIRED
  },
  {
    pattern: IS_VALID_PHONE_NUMBER,
    message: WarningMessage.PHONE
  }
];

export const rulePostalCode = [
  {
    required: true,
    message: WarningMessage.REQUIRED
  },
  {
    pattern: IS_VALID_POSTAL_CODE,
    message: WarningMessage.POSTAL
  }
];

export const rulePostalCodeNotRequire = [
  {
    pattern: IS_VALID_POSTAL_CODE,
    message: WarningMessage.POSTAL
  }
];

export const ruleMaxLength100 = [
  {
    pattern: IS_VALID_EXACT_100_CHARS,
    message: WarningMessage.MAX_LENGTH_100
  }
];

export const ruleRequired = [
  {
    required: true,
    message: WarningMessage.REQUIRED,
    validator: (rule: any, value: any) => {
      if (
        value === undefined ||
        value === null ||
        (typeof value === 'string' && value.trim() === '') ||
        (value instanceof Date && isNaN(value.getTime()))
      ) {
        return Promise.reject(WarningMessage.REQUIRED);
      }
      return Promise.resolve(); // Hợp lệ
    }
  }
];

export const ruleOnlySpaceRequired = [
  {
    required: true,
    message: WarningMessage.REQUIRED,
    validator: (rule: any, value: any) => {
      if (
        (typeof value === 'string' &&
          value.length > 0 &&
          value.trim() === '') ||
        (value instanceof Date && isNaN(value.getTime()))
      ) {
        return Promise.reject(WarningMessage.REQUIRED);
      }
      return Promise.resolve(); // Hợp lệ
    }
  }
];

export const ruleRequired2 = [
  {
    required: true,
    message: WarningMessage.NOT_ENTER,
    validator: (rule: any, value: any) => {
      if (
        value === undefined ||
        value === null ||
        (typeof value === 'string' && value.trim() === '') ||
        (value instanceof Date && isNaN(value.getTime()))
      ) {
        return Promise.reject(WarningMessage.NOT_ENTER);
      }
      return Promise.resolve();
    }
  }
];

export const ruleEmail = [
  {
    pattern: IS_VALID_EMAIL,
    message: WarningMessage.EMAIL
  }
];

export const ruleMin12 = [
  {
    pattern: IS_VALID_MIN_12_CHARS,
    message: WarningMessage.NOT_ENOUGH_12
  }
];

export const ruleExact13Chars = [
  {
    pattern: IS_VALID_EXACT_13_CHARS,
    message: WarningMessage.EXACT_LENGTH_13
  }
];

export const ruleExact10Chars = [
  {
    pattern: IS_VALID_MAX_10_CHARS,
    message: WarningMessage.EXACT_LENGTH_10
  }
];

export const rulesMaxLength = [20, 13, 50, 100, 2000].reduce(
  (acc: Object, maxLength: number) => {
    return Object.assign({}, acc, {
      [`${maxLength}`]: [
        () => ({
          validator: (_: any, value: string) => {
            if (value?.trim().length > maxLength) {
              return Promise.reject(
                WarningMessage.MAX_LENGTH.replace('{max}', maxLength.toString())
              );
            }
            return Promise.resolve();
          }
        })
      ]
    });
  },
  {}
);

export const ruleFax = [
  {
    pattern: IS_NUMBER,
    message: WarningMessage.FAX
  }
];

export const ruleNumber = [
  {
    pattern: IS_NUMBER,
    message: WarningMessage.NUMBER
  }
];

const skipFields = (key: string) => {
  const skipFields = [
    'construct_at',
    'performed_at',
    'start_order',
    'end_order',
    'start_complete',
    'end_complete',
    'password'
  ];
  return skipFields.includes(key);
};

export const trimStrings: any = (obj: any) => {
  if (typeof obj === 'string') {
    return obj.trim() || null;
  }

  if (Array.isArray(obj)) {
    return obj.map(trimStrings);
  }

  if (typeof obj === 'object' && obj !== null) {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => {
        if (skipFields(key)) {
          return [key, value];
        }

        const trimmedValue = trimStrings(value);

        if (typeof trimmedValue === 'string' && trimmedValue.trim() === '') {
          return [key, null];
        }

        return [key, trimmedValue];
      })
    );
  }

  return obj;
};

export const removeNullishValues = (obj: Object): Object => {
  return Object.entries(obj)?.reduce((acc, [key, value]) => {
    if (value !== undefined && value !== null) {
      Object.assign(acc, {
        [key]: value
      });
    }

    return acc;
  }, {});
};

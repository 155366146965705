export const Progress = {
  NOT_CONFIRMED: '1',
  NOT_COORDINATED: '2',
  COORDINATED: '3',
  COMPLETED: '4',
  REPORT_RECEIVED: '5',
  PDF_SENT: '6',
  ORIGINAL_SENT: '7',
  ALL: '0'
};
export const MappingProgressLabel = {
  [Progress.NOT_CONFIRMED]: '未確定',
  [Progress.NOT_COORDINATED]: '未手配',
  [Progress.COORDINATED]: '手配済',
  [Progress.COMPLETED]: '作業完了',
  [Progress.REPORT_RECEIVED]: '報告受領',
  [Progress.PDF_SENT]: 'PDF送付済',
  [Progress.ORIGINAL_SENT]: '完了(原本送付・破棄)',
  [Progress.ALL]: 'すべて'
};

export const MappingColor = {
  [Progress.NOT_CONFIRMED]: 'bg-[var(--color-not-confirm-2)]',
  [Progress.NOT_COORDINATED]: 'bg-[var(--color-not-coordinated-2)]',
  [Progress.COORDINATED]: 'bg-[var(--color-coordinated-2)]',
  [Progress.COMPLETED]: 'bg-[var(--color-completed-2)]',
  [Progress.REPORT_RECEIVED]: 'bg-[var(--color-report-received-2)]',
  [Progress.PDF_SENT]: 'bg-[var(--color-pdf-sent-2)]',
  [Progress.ORIGINAL_SENT]: 'bg-[var(--color-original-sent-2)]'
};

export const ContractItem = {
  REGULAR_CLEANING: '1',
  BUILDING_PATROL: '2',
  WATERWORKS_INSPECTION: '3',
  FIRE_INSPECTION: '4',
  EV_INSPECTION: '5',
  WATER_TANK_CLEANING: '6',
  PUMP_INSPECTION: '7',
  SEPTIC_TANK_MAINTENANCE: '8',
  DRAINAGE_PIPES: '9',
  OTHERS: '10'
};

export const ScheduleHistoriesType = {
  CREATE_NEW_SCHEDULE: 1,
  ADD_NEW_FIELD: 2,
  UPDATE_VALUE: 3
};

export enum ScheduleFieldsChange {
  STATUS = 'status',
  PERFORMED_AT = 'performed_at',
  FACILITY = 'facility_management_item',
  PERSON_IN_CHARGE = 'person_in_charge',
  VENDOR = 'item_order_company',
  NOTE = 'note'
}

export const MappingScheduleFieldsChange = {
  [ScheduleFieldsChange.STATUS]: 'ステータス',
  [ScheduleFieldsChange.PERFORMED_AT]: '実施日',
  [ScheduleFieldsChange.FACILITY]: '項目',
  [ScheduleFieldsChange.PERSON_IN_CHARGE]: '担当者',
  [ScheduleFieldsChange.VENDOR]: '発注先',
  [ScheduleFieldsChange.NOTE]: '備考'
};

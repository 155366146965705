import { Spin } from 'core-ui';
import { Navigate, useLocation } from 'react-router-dom';
import { RoutePath } from 'src/enums/routePath';
import { IPrivateRoute } from 'src/models/router';
import { useProfile } from 'src/pages/BasicFunction/Account/hooks/useAccount';
import { useGetAuthState, useSetAuthState } from 'src/redux/auth/authSlice';

export const PrivateRoute: React.FC<IPrivateRoute> = ({ children }) => {
  const { isAuthenticated } = useGetAuthState();
  const { loginRedux, setProfile } = useSetAuthState();
  const { pathname } = useLocation();
  const { isLoading } = useProfile({
    onSuccess: (data) => {
      setProfile(data?.data?.data);
      loginRedux();
    }
  });

  return isLoading ? (
    <div className="flex justify-center items-center h-[100vh]">
      <Spin size="large" />
    </div>
  ) : isAuthenticated ? (
    children
  ) : (
    <Navigate to={RoutePath.LOGIN} state={{ lastPathname: pathname }} />
  );
};

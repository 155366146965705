import { ReactElement } from 'react';

interface ICustomHeader {
  title: string;
  element?: ReactElement;
  editSuggestion?: boolean;
}
const CustomSubHeader = (props: ICustomHeader) => {
  const { editSuggestion = false, title, element } = props;
  return (
    <>
      <div className="flex justify-between items-center h-[63px]">
        <div
          className={`text-[20px] font-bold leading-[29px] text-left text-[#333333] ${editSuggestion ? 'pl-[60px]' : ''}`}
        >
          {title}
        </div>
        <div>{element}</div>
      </div>
      <div className="absolute transform left-[-30px] lg:left-[-88px] right-0 border-t-[1px] border-grey-600"></div>
    </>
  );
};

export default CustomSubHeader;

import { Button, DatePicker, LeftOutlined, RightOutlined } from 'core-ui';
import { useState } from 'react';
import { dayjs } from 'core-helpers';
import { DATE_FORMAT_5 } from 'src/constants/date';
import './index.scss';

const MonthPickerCustom = ({
  onChangeSelectedDate,
  selectedDate
}: {
  onChangeSelectedDate: (date: dayjs.Dayjs) => void;
  selectedDate: dayjs.Dayjs;
}) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const handleDateChange = (date: dayjs.Dayjs) => {
    onChangeSelectedDate(date);
    setShowPopup(false);
  };

  const handleButtonClick = () => {
    setShowPopup(!showPopup);
  };

  const handleMonthChange = (direction: 'prev' | 'next') => {
    onChangeSelectedDate(
      selectedDate.add(direction === 'prev' ? -1 : 1, 'month')
    );
  };

  return (
    <div className="month-picker-custom">
      <div className="flex gap-x-3 items-center justify-center mt-10">
        <Button
          className="w-[28px] flex justify-center items-center"
          onClick={() => handleMonthChange('prev')}
        >
          <LeftOutlined />
        </Button>
        <Button
          className="text-[24px] flex items-center justify-center weight-700 text-[var(--text-default-color)]"
          onClick={handleButtonClick}
        >
          {dayjs(selectedDate.toDate()).format(DATE_FORMAT_5)}
        </Button>
        <Button
          className="w-[28px] flex justify-center items-center"
          onClick={() => handleMonthChange('next')}
        >
          <RightOutlined />
        </Button>
      </div>
      <DatePicker
        value={selectedDate}
        picker="month"
        open={showPopup}
        onChange={handleDateChange}
      />
    </div>
  );
};

export default MonthPickerCustom;

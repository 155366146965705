import { apiRequest } from 'src/utils/api';
import { ApiPath } from 'src/enums/apiPath';
import { IAccount } from 'src/models/account';

export const getListAccount = async () => {
  return await apiRequest.get({
    url: `${ApiPath.ACCOUNT}/list?limit=-1`
  });
};

export const getAccountById = async (id: string) => {
  return await apiRequest.get({
    url: `${ApiPath.ACCOUNT}/${id}`
  });
};

export const createAccount = async (account: IAccount) => {
  return await apiRequest.post({
    url: ApiPath.ACCOUNT,
    data: account
  });
};

export const updateAccount = async (account: IAccount) => {
  return await apiRequest.put({
    url: `${ApiPath.ACCOUNT}/${account.id}`,
    data: account
  });
};

export const deleteAccount = async (id: string) => {
  return await apiRequest.delete({
    url: `${ApiPath.ACCOUNT}/${id}`
  });
};

export const getProfile = async () => {
  return await apiRequest.get({
    url: `${ApiPath.PROFILE}`
  });
};

export const logout = async () => {
  return await apiRequest.post({
    url: ApiPath.LOGOUT,
    data: {}
  });
};

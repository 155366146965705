import { jsCookie, jwtDecode } from 'core-helpers';
import { ApiPath } from 'src/enums/apiPath';
import { CookieKeys } from 'src/enums/cookie';
import {
  LoginFormI,
  RegisterFormI,
  ResetPasswordI,
  TokenDecodedI,
  UpdatePasswordI
} from 'src/models/auth';
import { handleLogoutRedux } from 'src/redux/auth/authSlice';
import { apiRequest } from 'src/utils/api';
import { clearCookies } from 'src/utils/cookies';
import { timestampToDate } from 'src/utils/date';

export const login = async (payload: LoginFormI) => {
  const res = await apiRequest.post({
    url: ApiPath.LOGIN,
    data: payload
  });

  const accessToken = res?.data?.data?.access_token;
  const decoded = jwtDecode(accessToken) as TokenDecodedI;

  const expiredDate = timestampToDate(Number(decoded?.exp));

  if (!!accessToken) {
    jsCookie.set(CookieKeys.ACCESS_TOKEN_KEY, accessToken, {
      expires: expiredDate
    });
  }

  jsCookie.set(CookieKeys.USER_ROLE_KEY, String(decoded?.permission_type));

  return res;
};

export const register = async (payload: RegisterFormI) => {
  return await apiRequest.post({
    url: ApiPath.REGISTER,
    data: payload
  });
};

export const logout = () => {
  clearCookies();
  handleLogoutRedux();
};

export const resetPassword = async (data: ResetPasswordI) => {
  return apiRequest.post({
    url: ApiPath.RESET_PASSWORD,
    data
  });
};

export const updatePassword = async (data: UpdatePasswordI) => {
  return apiRequest.put({
    url: ApiPath.UPDATE_PASSWORD,
    data
  });
};

import { arrayHelper } from 'core-helpers';

export const contentLabels: string[] = [
  '定期清掃',
  '建物巡回',
  '水道検診',
  '消防点検',
  'EV点検',
  '貯水槽清掃',
  'ポンプ点検',
  '浄化槽保守',
  '排水管',
  'その他'
];

const buildingLabels: string[] = [
  'マンション',
  'アパート',
  '戸建',
  '駐車場',
  '土地',
  'テナント'
];

const structureLabels: string[] = [
  'SRC造',
  'RC造',
  '鉄骨造',
  '重量鉄骨造',
  '軽量鉄骨造',
  '木造',
  'アスファルト',
  '砂利'
];

const billingLabels: string[] = [
  '月末締め10日請求',
  '月末締め10日請求（分割）'
];

const paymentLabels: string[] = [
  '共用電気料',
  '共同水道料',
  '町内会費',
  'CATV'
];

const mapLabelsToOptions = (labels: string[]) => {
  return labels.map((label, index) => ({
    label,
    value: index + 1
  }));
};

export const contentOptions = mapLabelsToOptions(contentLabels);

export const paymentOptions = [
  {
    label: '共用電気料',
    key: 'shared_electricity_charge',
    value: '1'
  },
  {
    label: '共同水道料',
    key: 'common_water_charge',
    value: '2'
  },
  {
    label: '町内会費',
    key: 'neighbor_association_fee',
    value: '3'
  },
  {
    label: 'CATV',
    key: 'catv',
    value: '4'
  }
];

export const END_OF_MONTH = 32;

export const InstallmentPayment = {
  NONE: '1',
  CAN_BE: '2'
};

type MappingOption = Map<
  number | string | undefined,
  {
    label: string;
    value: number;
  }
>;

export const buildingOptions = mapLabelsToOptions(buildingLabels);

export const structureOptions = mapLabelsToOptions(structureLabels);

export const billingOptions = mapLabelsToOptions(billingLabels);

export const payOptions = mapLabelsToOptions(paymentLabels);

export const MapBuildingOptionByValue: MappingOption = arrayHelper.mapping(
  buildingOptions,
  'value'
);

export const MapStructureOptionByValue: MappingOption = arrayHelper.mapping(
  structureOptions,
  'value'
);

export const MapContentOptionByValue: MappingOption = arrayHelper.mapping(
  contentOptions,
  'value'
);

export const MapPaymentOptionByValue = arrayHelper.mapping(
  paymentOptions,
  'value'
);

export const MapBillingByValue: MappingOption = arrayHelper.mapping(
  billingOptions,
  'value'
);

import { Button } from 'core-ui';
import React, { ErrorInfo, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { withRouter } from 'src/hocs/withRouter';
interface ErrorBoundaryProps {
  children?: ReactNode;
  location: ReturnType<typeof useLocation>;
  params: Record<string, string>;
  navigate: ReturnType<typeof useNavigate>;
}
interface ErrorBoundaryState {
  hasError: boolean;
  redirect?: boolean;
}
class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return {
      hasError: true
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error({ error, errorInfo });
  }

  componentDidUpdate(
    prevProps: ErrorBoundaryProps,
    prevState: ErrorBoundaryState
  ): void {
    if (prevProps && prevProps.location.key !== this.props.location.key) {
      if (prevState.hasError === true) {
        this.setState({ hasError: false });
      }
    }
  }

  handleGoBack = () => {
    const { navigate } = this.props;
    navigate(-1);
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="w-full h-[100vh] text-center flex justify-center items-center">
          <div className="not-found-main flex justify-end items-center flex-col">
            <div className="font-bold text-[20px] text-font-primary-400 mb-[21px]">
              予期しないエラーが発生しました。{' '}
            </div>
            <div className="leading-[26px] text-primary-200 text-sm font-[410] w-[433px] mb-[45px]">
              現在、システムで予期しないエラーが発生しています。
              <br />
              ご不便をおかけして申し訳ございません。
            </div>
            <Button
              onClick={this.handleGoBack}
              className="w-[240px] h-[47px] flex items-center justify-center bg-primary-500 rounded-[54px] text-base text-white font-[500]"
            >
              戻る
            </Button>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default withRouter<ErrorBoundaryProps>(ErrorBoundary);

import { useEffect } from 'react';
import { subscribe, unsubscribe, EventI } from 'src/utils/event';

export default function useSubscribeEvent(events: EventI[] = []) {
  useEffect(() => {
    events?.forEach((event) => subscribe(event));

    return () => {
      events?.forEach((event) => unsubscribe(event));
    };
  }, [events]);
}

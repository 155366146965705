import { ApiPath } from 'src/enums/apiPath';
import {
  ISchedule,
  IScheduleHistoriesParams,
  IScheduleParams
} from 'src/models/schedule';
import { apiRequest } from 'src/utils/api';

export const getListSchedule = async (params?: IScheduleParams) => {
  return await apiRequest.get({
    url: ApiPath.SCHEDULES,
    params
  });
};

export const getScheduleById = async (id: string) => {
  return await apiRequest.get({
    url: `${ApiPath.SCHEDULES}/${id}`
  });
};

export const deleteSchedule = async (id: string) => {
  return await apiRequest.delete({
    url: `${ApiPath.SCHEDULES}/${id}`
  });
};

export const updateSchedule = async (schedule: ISchedule) => {
  return await apiRequest.put({
    url: `${ApiPath.SCHEDULES}/${schedule.id}`,
    data: schedule
  });
};

export const getListScheduleHistories = async (
  schedule: IScheduleHistoriesParams
) => {
  return await apiRequest.get({
    url: `${ApiPath.SCHEDULE_HISTORIES}/${schedule.schedule_id}`
  });
};

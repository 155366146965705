import { combineReducers } from '@reduxjs/toolkit';
import loadingFullScreenSlice from './loadingFullScreen/loadingFullScreenSlice';
import ownerSlice from './owner/ownerSlice';
import projectSlice from './project/projectSlice';
import { authSlice } from './auth/authSlice';

const rootReducer = combineReducers({
  [loadingFullScreenSlice.name]: loadingFullScreenSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [ownerSlice.name]: ownerSlice.reducer,
  [projectSlice.name]: projectSlice.reducer
});

export default rootReducer;
